import React from "react";

const Terms = () => {
  return (
    <>
      <div className="headline">
        <h1 className="title-PP">Términos y condiciones</h1>
      </div>

      <div className="text-PP">
        <div>
          <h3 className="subtitle-PP">Términos y condiciones</h3>

          <p className="par-PP" style={{ textAlign: "justify" }}>
            Por favor, tenga en cuenta que la sección titulada "Renuncia de
            Acción Colectiva y Acuerdo de Arbitraje" a continuación contiene una
            cláusula de arbitraje vinculante y una exención de acción colectiva.
            Afecta a sus derechos en cualquier disputa con nosotros, incluidas,
            sin limitación, las disputas surgidas de su uso de los Servicios y /
            o su compra y / o uso de cualquier servicio o producto. ¡Bienvenido!
            Estamos contentos de que estés aquí y te agradecemos por seleccionar
            los servicios que ofrecemos a través de nuestros sitios web,
            aplicaciones móviles o por teléfono (los "Servicios"). Como
            desarrollador de los Servicios ("nosotros", "nosotros" o
            "nuestros"), queremos proporcionarle los términos que rigen su uso
            de los Servicios que se indican a continuación (estos "Términos").
            Al registrarse o acceder de otro modo, descargar, instalar y / o
            usar los Servicios, está de acuerdo con todos estos Términos.
            <br />
            <br />
            Registro
            <br />
            <br />
            Nuestras aplicaciones móviles le permiten registrarse y crear una
            cuenta de usuario para usar los Servicios. Hacemos que el registro
            sea opcional a través de una opción de Checkout de Invitado, si lo
            prefiere. Al registrarse o de otro modo acceder, descargar, instalar
            y / o usar los Servicios, usted representa y garantiza que (a) tiene
            al menos dieciocho (18) años de edad y (b) nos ha proporcionado
            información verdadera, precisa y actual sobre usted durante el
            proceso de registro o de Checkout de Invitado (incluyendo, sin
            limitación, su nombre y dirección de correo electrónico válida).
            Reconoces y aceptas que la información errónea puede conducir a una
            incapacidad para completar tu transacción. Usted representa y
            garantiza que proporcionará y mantendrá información de cuenta
            verdadera, completa y actualizada, y mantendrá su dirección de
            correo electrónico y número de teléfono actualizados mientras su
            cuenta siga activa. Los datos de registro y otra información que
            proporcione están regulados por nuestra Política de Privacidad. No
            puede acceder ni usar los Servicios de ninguna manera si tiene menos
            de dieciocho (18) años de edad. Al proporcionarnos su información de
            contacto, usted acepta que podamos comunicarnos con usted en
            relación con su cuenta y / o pedidos. Usted es el único responsable
            de todas las actividades que ocurren bajo su cuenta y de asegurarse
            de que salga o cierre la sesión de su cuenta al final de cada sesión
            de uso. Su contraseña es confidencial y no puede darla a nadie más.
            Notificará inmediatamente cualquier uso no autorizado de su cuenta o
            contraseña u otra violación de seguridad conocida o sospechada por
            usted.
            <br />
            <br />
            Servicios mobiles
            <br />
            <br />
            Ciertos Servicios están disponibles a través de un dispositivo
            móvil, lo cual puede incluir, sin limitación (a) la capacidad de
            cargar contenido a los Servicios, (b) la capacidad de navegar por
            los Servicios y (c) la capacidad de acceder a ciertas funciones a
            través de una aplicación descargada e instalada (colectivamente, los
            "Servicios Móviles"). En la medida en que acceda a los Servicios a
            través de un dispositivo móvil, las tarifas estándar de su proveedor
            de servicio de telefonía móvil, tasas de datos y otros cargos pueden
            aplicarse. Además, descargar, instalar o usar ciertos Servicios
            Móviles puede estar prohibido o restringido por su proveedor, y
            puede ser el caso de que no todos los Servicios Móviles funcionen
            con todos los proveedores o dispositivos. Al usar los Servicios
            Móviles, reconoce y acepta que cierta información sobre su uso de
            los Servicios Móviles puede comunicarse a nosotros.
            <br />
            <br />
            Tu contenido
            <br />
            <br />
            Estamos en el negocio de ofrecer productos personalizados (los
            "Productos"). Los Productos y / o el uso de los Servicios para pedir
            los Productos a veces requieren información que usted proporciona,
            incluyendo, sin limitación, texto, fotografías, imágenes y / o
            información que identifica, se relaciona, describe, puede estar
            asociada con o puede estar razonablemente vinculada, directa o
            indirectamente, con una persona particular, un hogar o un
            dispositivo electrónico (colectivamente, "Su Contenido"). Los
            Servicios y los Productos son para su uso personal únicamente. No
            puedes reproducir, duplicar, copiar, vender, revender ni de otra
            manera explotar cualquier parte de los Servicios o Productos excepto
            según lo permitido por estos Términos, y no puedes acceder o usar
            los Servicios para ningún propósito comercial. Cualquier uso de los
            Servicios distinto al expresamente autorizado en estos Términos está
            estrictamente prohibido. Nosotros nos reservamos expresamente todos
            los derechos no expresamente concedidos en estos Términos. <br />{" "}
            <br />
            Al enviarnos tu contenido, nos otorgas una licencia no exclusiva,
            mundial, libre de regalías, completamente pagada, transferible, con
            sublicencia, perpetua e irrevocable para reproducir, distribuir
            copias de, preparar trabajos derivados basados en, realizar
            públicamente, mostrar públicamente y de otra manera usar y explotar
            tu contenido con el propósito de proporcionar los Servicios y
            promocionarte otros servicios que creemos que te interesarán.
            Representas y garantizas que o bien eres dueño de tu contenido o
            tienes una licencia escrita u otra permiso válido del o los
            propietarios de los derechos correspondientes (por ejemplo, y sin
            limitación, tu fotógrafo) para poner tu contenido a nuestra
            disposición para usarlo con los Servicios y en los Productos que
            ordenes. <br />
            <br />
            Sin limitar la generalidad de lo anterior, reconoces y aceptas que
            tendremos que trabajar con Tu Contenido para proporcionarte los
            Servicios y los Productos. Por ejemplo, y sin limitación,
            copiaremos, y es posible que necesitemos mostrar, modificar, alojar,
            cargar, publicar, mostrar, enviar por correo electrónico o
            transmitir de otra forma (incluyendo la transmisión a otros países)
            o utilizar (en adelante, “Transmitir”), Tu Contenido cuando creamos
            los Productos, y distribuiremos Tu Contenido cuando enviamos los
            Productos a ti. Con la licencia otorgada anteriormente, nos estás
            dando permiso para hacer esto y, debido a que estamos usando Tu
            Contenido para proporcionarte los Servicios y los Productos, no nos
            cobrarás ninguna regalía. Retendremos Tu Contenido en nuestros
            servidores para que esté disponible para su futuro uso. Esto
            facilitará que puedas volver a imprimir pedidos completos o utilizar
            elementos de Tu Contenido para crear Productos para nuevos pedidos,
            todo sin necesidad de volver a cargar Tu Contenido. Acordamos que el
            uso que hagamos de Tu Contenido se limitará a la ámbito de los
            Servicios y los Productos que proporcionamos. Y descuida, nunca
            usaremos Tu Contenido para promocionar, promover o publicitar los
            Servicios a otros sin tu permiso. <br />
            <br />
            Reservamos el derecho de rechazar cualquiera o todo su contenido si
            determinamos, a nuestro criterio exclusivo, que su contenido es
            inapropiado o de otra manera está en desacuerdo con el ejercicio de
            un buen juicio. Por ejemplo, y sin limitación, podemos rechazar
            cualquiera de su contenido que creemos que caiga dentro del uso
            prohibido de los servicios establecidos a continuación. <br />
            <br />
            Nuestra Propiedad Intelectual <br />
            <br />
            Reconociendo y acordando que los Servicios contienen obras de
            autoría (por ejemplo, pero sin limitación, software), inventos,
            marcas y contenido) (“Nuestra Propiedad Intelectual”) que está
            protegida por derechos de autor, patentes, marcas comerciales,
            secretos comerciales y/u otras leyes, regulaciones y reglas y es
            propiedad nuestra y/u de nuestros licenciantes o afiliados. <br />
            <br />
            Sin limitación, a menos que se designe lo contrario, nosotros
            poseemos toda Nuestra Propiedad Intelectual, incluyendo, sin
            limitación, todas las marcas comerciales y logotipos utilizados en
            relación con los Servicios (“Nuestras Marcas Comerciales”). Nada en
            estos Términos o de otra manera concede ninguna licencia o derecho a
            usar cualquiera de Nuestras Marcas Comerciales. Usted no puede
            eliminar, cambiar o modificar de ninguna manera los avisos de
            derechos de autor, marca comercial o otra propiedad intelectual
            contenidos en los Servicios. Toda buena voluntad generada a partir
            del uso de Nuestras Marcas Comerciales recaerá en nuestro beneficio
            exclusivo. <br />
            <br />
            El uso de impresiones, imágenes o capturas de pantalla de los
            Servicios está limitado a su uso personal y no comercial. No puede
            usar ningún método de minería de datos, robots, scraping u otros
            métodos similares de recopilación de datos o extracción en relación
            con su uso de los Servicios. No reproducirá, distribuirá copias de,
            preparará obras derivadas basadas en, realizará públicamente,
            exhibirá públicamente o de otra manera utilizará, explotará,
            desensamblará en sentido inverso o de otra manera intentará
            descubrir cualquier código fuente, o intentará vender, asignar,
            otorgar una sublicencia o de otra forma transferir cualquier derecho
            en o a Nuestra Propiedad Intelectual. <br />
            <br />
            Propiedad Intelectual de Terceros <br />
            <br />
            Algunos de los Servicios y los Productos contienen imágenes,
            fotografías, diseños, diseños y otros contenidos que pueden
            consistir en los derechos de autor, marcas comerciales, marcas de
            servicio, nombres comerciales y otras propiedades intelectuales de
            terceros (colectivamente, el "Contenido de Terceros"). Se
            proporciona el Contenido de Terceros para su comodidad con los fines
            específicos para los cuales lo hemos proporcionado. No puede usar el
            Contenido de Terceros: <br />
            1. Para ningún otro propósito sin la autorización previa y expresa
            de su propietario; <br />
            2. Excepto únicamente como incorporado en un Producto, o descargarlo
            de otra manera en su forma original y sin alteraciones fuera del
            Servicio; <br />
            3. En cualquier forma que violaría la sección de Uso Prohibido de
            los Servicios de estos Términos; <br />
            4. Como marca comercial, marca de servicio o logotipo. <br />
            <br />
            Usos Prohibidos de los Servicios <br />
            <br />
            Usted es el único responsable de Su Contenido que transmite a través
            de los Servicios. Nos reservamos el derecho de investigar y tomar
            las medidas legales oportunas contra cualquier persona que, a
            nuestro exclusivo criterio, viola estos Términos, incluyendo, sin
            limitación, eliminar cualquier contenido ofensivo de los Servicios,
            suspender o terminar cualquier cuenta de dicho/s infractor/es y
            notificar a dicho/s infractor/es a las autoridades policiales
            pertinentes. Como condición para acceder y usar los Servicios,
            acuerda no usar los Servicios para: <br />
            <br />
            1. No transmitir ningún contenido que (a) sea ilegal, amenazante,
            abusivo, acosador, tortuoso, violento, difamatorio, calumnioso,
            injurioso, grosero, obsceno, pornográfico (involucrando a menores o
            de otra manera), odioso o abusivo, o de otra manera igualmente
            objetable, (b) represente a cualquier persona representada de manera
            que una persona razonable la encontraría ofensiva o represente a
            cualquier persona [1] en relación con la pornografía, los "videos
            para adultos", los locales de entretenimiento para adultos, los
            servicios de acompañantes, los servicios de citas u otros similares,
            [2] en relación con la publicidad o promoción de productos de
            tabaco, [3] como sufriendo o medicándose de una dolencia física o
            mental, o [4] participando en actividades inmorales o criminales,
            (c) suponga o cree un riesgo de privacidad o seguridad para
            cualquier persona, (d) constituya publicidad, material promocional,
            correo basura, spam, cadenas de cartas, esquemas de pirámides,
            concursos, sorteos u otro tipo de solicitud no solicitados o no
            autorizados, (e) caracterice cualquier actividad ilegal o inmoral
            como aceptable, glamorosa o deseable, (f) glamurice el uso o los
            "drogas duras" ilegales, (g) denigre o ofenda a cualquier grupo
            étnico, racial, sexual o religioso, o a personas que estén
            físicamente o mentalmente desafíadas, (h) haga un uso inapropiado de
            esvásticas u otros símbolos de racismo o intolerancia y/o glamurice
            las acciones de Hitler u otros individuos o grupos que abogan por el
            limpieza étnica, el genocidio, la eliminación o destrucción de un
            país o su gobierno, civilización o grupo étnico, o actividades
            similares, (i) utilice mensajes, marcas o símbolos que apoyen cultos
            o teorías de la conspiración que han sido desacreditadas o que están
            basadas, en parte o en su totalidad, en la intolerancia o (j)
            contenga imágenes o parecidos de menores sin la autoridad válida
            para hacerlo. <br />
            2. Acosar a otra persona por cualquier medio, incluyendo, sin
            limitación, el uso de su cuenta de correo electrónico, dirección o
            contraseña, o falsear o de otra manera tergiversar su afiliación con
            cualquier persona o entidad. <br />
            <br />
            3. Violar cualquier ley, regulación o regla aplicable local,
            estatal, nacional o internacional. <br />
            4. Transmitir o hacer disponible cualquier contenido del que no se
            tenga derecho legal para transmitir, que infrinja los derechos
            intelectuales o de propiedad de terceros (incluyendo, sin
            limitación, derechos de autor, secretos comerciales, marcas
            comerciales, marcas de servicio o patentes), o que violaría los
            derechos de privacidad o publicidad de cualquier persona. <br />
            5. Realizar cualquier conducta que interrumpa, destruya, limite o
            dañe los Servicios o le permita obtener acceso no autorizado a los
            Servicios, incluyendo, sin limitación, el uso de virus, caballos de
            Troya, gusanos o código informático malicioso, programas o archivos;{" "}
            <br />
            6. Reproducir, copiar, vender o usar comercialmente (incluyendo, sin
            limitación, el derecho de acceso) los Servicios; <br />
            7. Solicitar información personal de alguien menor de dieciocho (18)
            años; <br />
            8. Recopilar o recolectar direcciones de correo electrónico u otra
            información de contacto de otros usuarios de los Servicios por
            medios electrónicos u otros; <br />
            9. Promover cualquier actividad criminal o proporcionar información
            de instrucción sobre actividades ilegales. <br />
            <br />
            En caso de violación de lo anterior, y sin limitación, nos
            reservamos el derecho de terminar su cuenta y/o bloquearle el acceso
            a los Servicios y revelar cualquier información si es necesario
            hacerlo por ley o en la buena fe de creer que tal preservación o
            revelación es razonablemente necesaria para (a) cumplir con el
            proceso legal, las leyes aplicables o las solicitudes del gobierno,
            (b) hacer cumplir estos Términos, (c) responder a reclamaciones de
            que cualquier contenido viola los derechos de terceros o (d)
            proteger los derechos, la propiedad o la seguridad personal de
            nosotros, nuestros usuarios o el público. <br />
            <br />
            Información que usted proporciona <br />
            <br />
            Cualquier pregunta, comentario, sugerencia, idea, comentario u otra
            información sobre los Servicios (“Envíos”) proporcionados por usted
            a nosotros son de carácter no confidencial, y nosotros tendremos
            derecho al uso y distribución sin restricciones de los Envíos para
            cualquier propósito, sin reconocimiento ni compensación para usted.{" "}
            <br />
            <br />
            Practicas sobre el Uso y el Almacenamiento de Su Contenido <br />
            <br />
            Podemos preservar Su Contenido y también podemos eliminar o revelar
            Su Contenido si se requiere hacerlo por la ley o en la buena fe de
            que tal preservación o divulgación es necesariamente razonable para
            (a) cumplir con proceso legal, leyes aplicables o solicitudes del
            gobierno, (b) hacer cumplir estos Términos, (c) responder a reclamos
            de que algún contenido viola los derechos de terceros o (d) proteger
            los derechos, la propiedad o la seguridad personal de nosotros,
            nuestros usuarios o el público. Podemos establecer prácticas y
            límites en relación con el uso de los Servicios, incluyendo, sin
            limitación, el período máximo de tiempo en que los datos u otros
            contenidos se mantienen por los Servicios y el espacio de
            almacenamiento máximo que se asignará en nuestros servidores en su
            nombre. No tendremos responsabilidad o responsabilidad por la
            eliminación o falla para almacenar cualquier dato o otro contenido
            mantenido o Transmitido por o a los Servicios. Usted reconoce y
            acepta que nos reservamos el derecho de terminar cuentas que estén
            inactivas durante un período de tiempo extendido, tal tiempo será
            determinado a nuestro exclusivo criterio. Usted reconoce y acepta
            además que nos reservamos el derecho de cambiar estas prácticas
            generales y límites en cualquier momento, a nuestro exclusivo
            criterio, con o sin previo aviso. <br />
            <br />
            Recompensas <br />
            <br />
            De vez en cuando, podemos ofrecerte ciertas ofertas y/o
            funcionalidades que llamamos "Recompensas". No hay tarifas de
            membresía asociadas con las Recompensas. Las Recompensas son
            estrictamente promocionales y no tienen valor en efectivo. Para
            desbloquear una Recompensa disponible, debes seleccionar la
            Recompensa a través de la pantalla de Recompensas y completar la
            acción requerida. Por ejemplo, y sin limitación, podemos ofrecer un
            determinado número de productos o servicios gratuitos si completas
            un determinado número de transacciones en un período de tiempo
            limitado. Una vez desbloqueado, puedes canjear o activar la
            Recompensa siempre que esté disponible a través de tu cuenta. Cada
            Recompensa se limita a una (1) por cliente a menos que indiquemos lo
            contrario. Nos reservamos el derecho de cambiar, modificar y/o
            eliminar cualquier y todas las Recompensas, independientemente de su
            estado (incluyendo, sin limitación, Recompensas que estén
            disponibles, desbloqueadas o canjeadas) en cualquier momento y a
            nuestra entera discreción. Cualquier cambio, modificación o
            eliminación entrará en vigor de inmediato con la liberación de una
            actualización aplicable a los Servicios y renuncias a cualquier
            derecho que puedas tener a recibir un aviso específico y/o previo de
            cualquier cambio, modificación o eliminación. Si tomas alguna acción
            que resulte en el desbloqueo y/o canje de cualquier Recompensa y
            dicha acción se considera incompleta o anulada por cualquier motivo,
            podemos deducir, eliminar o eliminar la Recompensa correspondiente a
            nuestra entera discreción. Tus Recompensas son personales y no se
            pueden vender, transferir ni asignar a otros ni usar por ti para
            ningún propósito comercial. <br />
            <br />
            Ofertas Promocionales <br />
            <br />
            De vez en cuando, podemos ofrecerle ciertas ofertas promocionales.
            Las ofertas de envío gratuito están sujetas a los siguientes
            términos y condiciones: <br />
            <br />
            - Solo se aplican a pedidos enviados a una sola dirección en una
            región geográfica a la que normalmente enviamos. <br />
            - Se limitan a un descuento por pedido y por cliente, y no se pueden
            combinar con otros descuentos, ofertas o promociones. <br />
            - No tienen valor monetario y no se pueden revender. <br />
            - Si tienen un requisito mínimo de pedido, el valor del pedido antes
            de impuestos debe cumplir con el umbral mínimo de pedido para que se
            active la oferta. <br />
            - No se aplican a (a) ningún pedido anterior (s), (b) entrega
            exprés, prioritaria o de última hora, (c) impuestos, (d) artículos
            de tamaño grande, (e) productos marcados especialmente o (f) compras
            al por mayor o corporativas de diez (10) unidades o más. <br />
            - Salvo que lo exija la ley, no se pueden canjear por efectivo,
            cheque o crédito. <br />
            - Las ofertas de dólares de descuento (y ofertas similares en
            lugares con otras monedas) están sujetas a los siguientes términos y
            condiciones: <br />
            - Están limitadas a un descuento por pedido y por cliente, y no se
            pueden combinar con otros descuentos, ofertas o promociones. <br />
            - No tienen valor en efectivo y no se pueden revender. <br />
            - Si tienen un requisito de pedido mínimo, el valor del pedido antes
            de impuestos debe cumplir con el umbral mínimo de pedido para que la
            oferta se active. <br />
            - No se aplican a (a) pedidos anteriores, (b) envío, cuidado,
            manipulación o impuestos, (c) productos marcados especialmente o (d)
            compras de grupo o corporativas de diez (10) unidades o más. <br />
            - Salvo que así lo exija la ley, no se pueden canjear por efectivo,
            cheque o crédito. <br />
            - No afectan los precios al por menor normales (que están sujetos a
            cambios). <br />
            - Las ofertas de Porcentaje de descuento están sujetas a los
            siguientes términos y condiciones: <br />
            - Están limitadas a un descuento por pedido y por cliente, y no se
            pueden combinar con otros descuentos, ofertas o promociones. <br />
            - No tienen valor en efectivo y no se pueden revender. <br />
            - Si tienen un requisito de pedido mínimo, el valor del pedido antes
            de impuestos debe cumplir con el umbral mínimo de pedido para que la
            oferta se active. <br />
            - No se aplican a (a) pedidos anteriores, (b) envío, cuidado,
            manipulación o impuestos, (c) productos marcados especialmente o (d)
            compras de grupo o corporativas de diez (10) unidades o más. <br />
            - Salvo que así lo exija la ley, no se pueden canjear por efectivo,
            cheque o crédito. <br />
            - No afectan los precios al por menor normales (que están sujetos a
            cambios). <br />
            <br />
            Tarjetas de regalo <br />
            <br />
            De vez en cuando tendrás la capacidad de comprar, recibir, enviar,
            recibir y/o activar a través de nosotros tarjetas de regalo
            digitales (cada una, una "Tarjeta de Regalo"). <br />
            Enviaremos cada Tarjeta de Regalo al destinatario para el cual el
            comprador haya proporcionado la información del destinatario que
            requerimos. El destinatario puede ser el propio comprador o un
            tercero. Las Tarjetas de Regalo son canjeables solo a través del
            comerciante que selecciona el comprador de la Tarjeta de Regalo en
            el proceso de compra (el "Comerciante"), y el saldo de la Tarjeta de
            Regalo solo está disponible a través de ese Comerciante. El saldo de
            la Tarjeta de Regalo no está disponible a través de nosotros.
            Proporcionaremos a cada destinatario de una Tarjeta de Regalo un
            código de activación para activar la Tarjeta de Regalo a través de
            nosotros, para que luego pueda canjearse con el Comerciante. Ese
            código de activación no tiene fecha de vencimiento ni tarifas. Los
            términos y condiciones del Comerciante también se aplican tanto para
            la activación como para el canje. Nos reservamos el derecho de
            discontinuar nuestra línea de productos de tarjetas de regalo o las
            tarjetas de regalo de cualquier Comerciante individual, incluyendo,
            sin limitación, antes de que el destinatario haya activado con
            nosotros o canjeado con el Comerciante una Tarjeta de Regalo ya
            comprada. Si una Tarjeta de Regalo se descontinúa después de ser
            comprada por el comprador de la Tarjeta de Regalo pero antes de ser
            activada con nosotros por el destinatario de la Tarjeta de Regalo,
            sustituiremos la Tarjeta de Regalo con nuestra elección de Tarjeta
            de Regalo de repuesto de terceros, a valor equivalente. Podemos
            proporcionar al comprador de la Tarjeta de Regalo información sobre
            el estado de activación de la Tarjeta de Regalo de su destinatario.{" "}
            <br />
            <br />
            Hasta que depositemos una Tarjeta de Regalo con un transportista
            (por ejemplo, Correos de Estados Unidos o Royal Mail) para su
            entrega al destinatario de la Tarjeta de Regalo, el riesgo de
            pérdida, y el título de la misma, le pertenece a nosotros. El riesgo
            de pérdida, y el título de la misma, se transfiere al destinatario
            de la Tarjeta de Regalo cuando la depositamos con un transportista
            para su entrega a dicho destinatario. El destinatario de la Tarjeta
            de Regalo es responsable de protegerla de uso no autorizado. No
            somos responsables si se pierde, roba o destruye alguna Tarjeta de
            Regalo entregada, o si se usa una Tarjeta de Regalo sin permiso. Hay
            una variedad de estafas que solicitan el pago con tarjeta de regalo.
            No somos responsables, y no asumimos ninguna responsabilidad para
            usted, por cualquier conducta ilegal o fraude por parte de cualquier
            tercero relacionado con cualquier Tarjeta de Regalo. <br />
            <br />
            Al usar una tarjeta de regalo, acepta cumplir con estos Términos y
            no usar una tarjeta de regalo de ninguna manera engañosa, engañosa,
            injusta o de otra manera dañina para nosotros, nuestros afiliados o
            nuestros clientes. Nos reservamos el derecho, sin previo aviso para
            usted, de anular una tarjeta de regalo sin reembolso, suspender o
            terminar cuentas de clientes, suspender o terminar la capacidad de
            usar nuestra aplicación (es) y / o sitio (s) web, cancelar o limitar
            los pedidos y facturar formas alternativas de pago si sospechamos
            que una tarjeta de regalo se obtiene, usa o aplica fraudulentamente,
            ilegalmente o de otra manera en violación de estos términos. <br />
            <br />
            Sitios, productos y servicios de terceros <br />
            <br />
            Puede habilitar diversos servicios en línea, como sitios de redes
            sociales, para que se integren directamente a su experiencia con los
            Servicios. Además, de vez en cuando, los Servicios pueden incluir
            enlaces a sitios, servicios y/o productos de terceros que creemos
            que pueden ser de su interés. Para aprovechar estas características,
            es posible que le pidamos que se registre o inicie sesión en los
            servicios de sus proveedores respectivos. Al habilitar los servicios
            de terceros dentro de los Servicios, está permitiendo y autorizando
            (a) que pasemos su información de inicio de sesión a estos
            proveedores de servicios con este propósito y (b) que recibamos
            información y contenido de tales servicios de terceros. Tenga en
            cuenta que la forma en que los servicios de terceros utilizan,
            almacenan y divulgan su información está regulada únicamente por las
            políticas de tales terceros, y no tendremos responsabilidad ni
            responsabilidad por las prácticas de privacidad ni por las demás
            acciones de ningún sitio o servicio de terceros que puedan
            habilitarse dentro de los Servicios. No hacemos ninguna
            representación ni garantía con respecto a dichos sitios, servicios o
            productos de terceros, y, por lo tanto, no somos responsables de
            ningún daño o pérdida causada o presuntamente causada por o en
            relación con el uso o la confianza en cualquier sitio, servicio o
            producto de terceros. <br />
            <br />
            Cookies <br />
            <br />
            Nuestros sitios web usan "cookies". Las cookies son pequeños
            archivos de texto que se almacenan en su dispositivo y lo
            identifican como un usuario único. Las cookies nos permiten, entre
            otras cosas, medir la actividad y personalizar su experiencia. Por
            ejemplo, y sin limitación, las cookies nos permiten recordar sus
            preferencias de visualización sin que tenga que volver a escribir un
            nombre de usuario o contraseña. Las cookies también nos permiten
            rastrear su estado o progreso al solicitar productos de nosotros. Si
            lo desea, puede configurar su dispositivo para rechazar cookies o
            puede eliminar manualmente individualmente o todas las cookies de su
            dispositivo. Sin embargo, si rechaza o elimina las cookies, es
            posible que tenga algunos problemas para acceder y usar algunas de
            las páginas y características de nuestros sitios web. También usamos
            otras herramientas comunes de recopilación de información como web
            beacons y enlaces web incrustados. <br />
            <br />
            Pagos <br />
            <br />
            Hasta el grado en que los Servicios y/o los Productos o cualquier
            parte de ellos se hagan disponibles por una tarifa o carga
            (incluyendo, sin limitación, tarifas de envío y manejo), deberá
            seleccionar una forma de pago y proporcionarnos información sobre su
            tarjeta de crédito u otra forma de pago autorizada por nosotros.
            Usted declara y garantiza que esta información es verdadera y
            correcta y que está autorizado para usar la forma de pago
            seleccionada. Cuando realice un pago, nos autoriza (y nuestros
            procesadores de pagos designados) a cobrar el monto total a la
            fuente de pago que designe para la transacción. Actualizará de
            manera oportuna la información de su cuenta con los cambios que
            puedan ocurrir (por ejemplo, y sin limitación, un cambio en su
            dirección de facturación o fecha de vencimiento de la tarjeta de
            crédito). Usted nos pagará todas las tarifas y cargos de acuerdo con
            la forma de pago autorizada y estos Términos. Si cuestiona alguna
            tarifa o cargo, nos lo hará saber dentro de los sesenta (60) días
            posteriores a la fecha en que nosotros le facturemos o cobremos de
            otra forma, y nos dará la oportunidad de remediar cualquier problema
            que usted considere que le da derecho a cuestionar esas tarifas o
            cargos. Usted nos otorga aquí el derecho de corregir cualquier error
            de procesamiento de pagos que descubramos, y el derecho de corregir
            cualquier error de procesamiento mediante el débito o abono del
            método de pago utilizado para la transacción que resulte errónea.{" "}
            <br />
            <br />
            Retornos y devoluciones <br />
            <br />
            Queremos que estés 100% satisfecho con tu experiencia con los
            Servicios y los Productos. Si dentro de los primeros treinta (30)
            días no estás completamente satisfecho con tu pedido, haremos lo
            siguiente: (a) proporcionaremos un reemplazo en caso de un defecto o
            (b) en todos los demás casos, emitiremos un reembolso completo,
            sujeto a las excepciones y restricciones enumeradas a continuación:
            Los reembolsos están limitados a las cantidades realmente pagadas
            por usted. Esto no incluye el valor promocional de cualquier oferta
            especial o descuento. En los casos en que la devolución/reembolso se
            debe a que su pedido llega dañado o con un defecto confirmado por
            nosotros, a nuestra sola discreción (a) le proporcionaremos una
            etiqueta de envío de devolución prepago, (b) reembolsaremos el costo
            de envío de devolución o (c) le diremos que deseche el producto
            dañado o defectuoso. Para todos los demás casos, los gastos de envío
            de devolución son su responsabilidad. Se pueden aplicar cargos por
            envío y manipulación a los Servicios y Productos que de otro modo se
            ofrecen en forma gratuita o promocional. Todos los pedidos que
            reciben precios por mayor/al por mayor son finales y no se dará
            ningún reembolso excepto en el caso de daños materiales o defectos
            en su pedido. Si el daño o defecto no se puede verificar por
            teléfono o por correo electrónico, es posible que tenga que devolver
            el artículo a nosotros para su inspección antes de que se pueda
            determinar un posible reembolso. Asegúrese de solicitar y revisar
            cuidadosamente las pruebas digitales al realizar un pedido por
            mayor/al por mayor. Además de la garantía de satisfacción anterior,
            también ofrecemos una garantía de por vida limitada contra defectos
            de fabricación en todos nuestros Productos de decoración de pared de
            lienzo, si tal defecto de fabricación hace que un Producto de
            decoración de pared de lienzo sea inutilizable para su propósito
            previsto de una manera que no existía en los primeros treinta (30)
            días después de que lo recibió. Para hacer una reclamación de esta
            garantía, contáctenos, ya sea a través de nuestro sitio web, a
            través de las herramientas de autoservicio que se pueden
            proporcionar en la bandeja o cajón de la aplicación si usa una de
            nuestras aplicaciones móviles, o por teléfono si está usando uno de
            los Servicios que proporciona soporte telefónico. <br />
            <br />
            Aclaracion de garantías <br />
            <br />
            USTED RECONOCE Y ACEPTA QUE LOS SERVICIOS Y LOS PRODUCTOS SE
            PROPORCIONAN "TAL CUAL, TAL CUAL ESTÁN DISPONIBLES", SIN NINGÚN TIPO
            DE GARANTÍA DISTINTA A LA ESTABLECIDA EN LA SECCIÓN DE DEVOLUCIONES
            Y REEMBOLSOS DE ESTOS TÉRMINOS. POR LA PRESENTE RENUNCIAMOS A TODAS
            LAS DEMÁS GARANTÍAS (EXPRESAS, IMPLÍCITAS, ESTATUTARIAS U OTRAS),
            INCLUYENDO, SIN LIMITACIÓN, TODAS LAS GARANTÍAS (A) RESPECTO A LOS
            SERVICIOS Y LOS PRODUCTOS (INCLUYENDO, SIN LIMITACIÓN, SU CALIDAD,
            DISPONIBILIDAD, RENDIMIENTO Y FUNCIONALIDAD), (B) RESPECTO A LA
            CALIDAD O PRECISIÓN DE CUALQUIER INFORMACIÓN OBTENIDA O DISPONIBLE A
            TRAVÉS DEL USO DE LOS SERVICIOS, (C) DE QUE LOS SERVICIOS SERÁN
            ININTERRUMPIDOS, LIBRES DE ERRORES, LIBRES DE VIRUS U OTROS
            COMPONENTES DAÑINOS O QUE FUNCIONARÁN EN O CON CUALQUIER HARDWARE,
            PLATAFORMA O SOFTWARE PARTICULAR, (D) DE NO INFRACCIÓN, (E) DE
            COMERCIALIZACIÓN Y (F) DE ADECUACIÓN PARA UN PROPÓSITO PARTICULAR.
            NINGÚN CONSEJO O INFORMACIÓN QUE NOSOTROS O NUESTROS REPRESENTANTES
            DEN SE CONSIDERARÁ QUE CREA UNA GARANTÍA. <br />
            <br />
            Limitacion de responsabilidad – General <br />
            <br />
            NO SEREMOS RESPONSABLES POR LA NO EJECUCIÓN O DEMORA EN LA EJECUCIÓN
            CAUSADA POR CUALQUIER RAZÓN, YA SEA DENTRO O FUERA DE NUESTRO
            CONTROL. EN NINGÚN CASO SEREMOS RESPONSABLES BAJO EL CONTRATO,
            NEGLIGENCIA, TORT, RESPONSABILIDAD ESTRICTA U OTRA TEORÍA LEGAL O
            EQUITATIVA POR DAÑOS INDIRECTOS, INCIDENTALES, EJEMPLARES,
            PUNITIVOS, ESPECIALES O EMERGENTES (INCLUYENDO, SIN LIMITACIÓN,
            PÉRDIDA DE USO, PÉRDIDA DE BENEFICIOS, PÉRDIDA DE DATOS O
            INFORMACIÓN O PÉRDIDA DE LA BUENA VOLUNTAD DE LA EMPRESA O
            OPORTUNIDADES) YA SEA QUE HAYAMOS SIDO INFORMADOS DE LA POSIBILIDAD
            DE TAL PÉRDIDA O DAÑOS. NO SEREMOS RESPONSABLES POR NINGÚN DAÑO QUE
            RESULTE DE (A) EL USO O LA IMPOSIBILIDAD DE USAR LOS SERVICIOS, (B)
            EL COSTO DE OBTENER BIENES Y SERVICIOS DE SUSTITUCIÓN, (C) EL ACCESO
            NO AUTORIZADO O ALTERACIÓN DE SUS TRANSMISIONES O DATOS (D)
            DECLARACIONES O CONDUCTA DE CUALQUIER TERCERO EN LOS SERVICIOS O (E)
            CUALQUIER DAÑO PERSONAL O DAÑOS A LA PROPIEDAD QUE RESULTE DEL USO
            DE CUALQUIER DE LOS PRODUCTOS. En ningún caso nuestra
            responsabilidad hacia usted excederá el mayor de cincuenta dólares
            estadounidenses ($50.00) o cualquier cantidad realmente pagada por
            usted a nosotros por los servicios o productos específicos en
            cuestión durante los seis (6) meses previos a la fecha en que se
            haya producido la base para el litigio. No se puede presentar
            ninguna acción, independientemente de su forma, derivada de estos
            términos o de los servicios y/o productos más de un (1) año después
            del evento que dio lugar a la causa de la acción. Algunas
            jurisdicciones no permiten una limitación a los daños por
            responsabilidad contenida en estos términos y, en tales
            jurisdicciones, nuestra responsabilidad se limitará al máximo
            permitido por la ley. <br />
            <br />
            Limitacion de responsabilidad – Restricciones de datos y uso <br />
            <br />
            NO SEREMOS RESPONSABLES DE NINGUNA MANERA POR CUALQUIER LÍMITE,
            TECHO O RESTRICCIÓN DE DATOS O USO, O POR CUALQUIER TARIFA O CARGO
            APLICADO O EVALUADO A TU DISPOSITIVO MÓVIL POR CUALQUIER TERCERO QUE
            RESULTE DE TU USO DE LOS SERVICIOS O DE OTRA MANERA. SUGERIMOS QUE,
            CUANDO ESTÉ DISPONIBLE, USES TU DISPOSITIVO MÓVIL CON UNA CONEXIÓN
            INALÁMBRICA SIN MEDIDOR PARA MINIMIZAR LA POSIBILIDAD DE QUE
            CUALQUIERA DE LOS ANTERIORES TE OCURRAN. <br />
            <br />
            Copyright y otros derechos de propiedad intelectual <br />
            <br />
            Respetamos los derechos de propiedad intelectual de terceros y le
            pedimos que haga lo mismo. De conformidad con la Ley de Derechos de
            Autor de la Milenio Digital (DMCA), si cree que su obra ha sido
            utilizada de manera que constituye una infracción de copyright, o
            que sus derechos de propiedad intelectual han sido violados de otra
            manera, proporcione la siguiente información, utilizando el
            formulario de contacto proporcionado en nuestro sitio web o en la
            bandeja o cajón de la aplicación móvil que está utilizando, si está
            disponible, según corresponda. También puede contactarnos por correo
            en 116 Buck Creek Trail, Alva, WY, USA. <br />- una descripción de
            la obra con derechos de autor u otros derechos de propiedad
            intelectual que usted afirma que ha sido infringida; <br />
            - una descripción de dónde se encuentra el material que usted afirma
            que está infringiendo en el sitio, junto con enlaces al material en
            cuestión; una declaración por parte de usted de que tiene una buena
            fe de que el uso disputado no está autorizado por el titular de los
            derechos de autor o de otra propiedad intelectual, su agente o la
            ley; <br />
            - una declaración por parte de usted, hecha bajo pena de perjurio,
            de que la información anterior en su aviso es exacta y que usted es
            el titular de los derechos de autor o propiedad intelectual o
            autorizado para actuar en nombre del titular de los derechos de
            autor o propiedad intelectual; <br />
            - su nombre, dirección, número de teléfono y dirección de correo
            electrónico; <br />
            - y su firma electrónica o física. <br />
            <br />
            Contra-Aviso: Si usted cree que el contenido que fue eliminado (o al
            que se ha deshabilitado el acceso) no es infractor, o que el uso en
            disputa está autorizado por el propietario del copyright u otra
            propiedad intelectual, su agente, o la ley, usted puede enviar un
            contra-aviso por escrito que contenga la siguiente información a
            nosotros: <br />
            - una descripción de la obra con derecho de autor o otra propiedad
            intelectual que ha sido eliminada o a la que se ha deshabilitado el
            acceso; <br />
            - una descripción de dónde se ubicaba tal material en el sitio antes
            de que lo eliminaran o deshabilitaran, junto con las URLs que
            llevaban al material en cuestión si las tiene; <br />
            - una declaración suya de que tiene una buena fe en que el contenido
            fue autorizado por el propietario del copyright u otra propiedad
            intelectual, su agente, o la ley y fue eliminado o deshabilitado
            como resultado de un error u omisión; <br />
            - una declaración suya, bajo pena de perjurio, de que la información
            anterior en su aviso es precisa y que usted es el propietario del
            copyright u otra propiedad intelectual o autorizado para actuar en
            nombre del propietario de la propiedad intelectual; <br />
            - su nombre, dirección, número de teléfono y dirección de correo
            electrónico, una declaración de que consiente en la jurisdicción del
            tribunal federal situado dentro del Distrito Central de California y
            una declaración de que aceptará el servicio de procesamiento de la
            persona que proporcionó la notificación de la presunta infracción o
            un agente de dicha persona; <br />
            - su firma electrónica o física. <br />
            Si se recibe un contra-aviso por nosotros, enviaremos una copia del
            contra-aviso a la parte originaria que presentó la queja/propietario
            de copyright. De acuerdo con la ley aplicable, podemos reemplazar o
            restaurar el acceso al contenido eliminado a menos que la parte
            originaria que presentó la queja/propietario de copyright nos
            notifique que está solicitando una orden judicial contra la presunta
            parte infractora para evitar una infracción ulterior del contenido
            en cuestión. Política de Infractores Repetitivos: De acuerdo con la
            DMCA y otras leyes aplicables, hemos adoptado una política de
            terminar, en circunstancias apropiadas y a nuestra entera
            discreción, las cuentas de usuarios que sean considerados
            infractores repetitivos. <br />
            <br />
            Terminación, Desactivación y Suspensión <br />
            <br />
            Usted o nosotros podemos terminar su cuenta en cualquier momento. Si
            viola estos Términos, también tendremos el derecho de desactivar o
            limitar su acceso a los Servicios. Usted acepta que cualquier
            terminación, limitación y/o desactivación puede ser efectuada por
            nosotros sin previo aviso, y que podemos eliminar inmediatamente
            toda la información relacionada, archivos y contenido de su cuenta.
            No seremos responsables de ninguna manera por tal terminación,
            desactivación, limitación o eliminación. <br />
            <br />
            Aviso especial para uso internacional; controles de exportación{" "}
            <br />
            <br />
            Todo el software disponible en relación con los Servicios y la
            transmisión de los datos aplicables, si los hay, está sujeto a los
            controles de exportación establecidos por la ley. No se puede
            descargar ningún software de los Servicios ni exportarlo ni
            reexportarlo en violación de las leyes de exportación. La descarga o
            el uso de dicho software corre por su cuenta y su riesgo, y usted
            debe cumplir con todas las leyes, regulaciones y normas
            internacionales y nacionales relativas a su uso de los Servicios.{" "}
            <br />
            <br />
            Indemnificacion <br />
            <br />
            Usted libera y nos exonera a nosotros y a nuestros padres,
            subsidiarios, afiliados, oficiales, accionistas, miembros,
            directores, gerentes, empleados, abogados, representantes y agentes,
            de todas las reclamaciones, costos, daños, pérdidas,
            responsabilidades y gastos (incluidos, sin limitación, honorarios y
            costos de abogados) y acciones y daños de todo tipo basados en,
            derivados de o relacionados con su uso de los Servicios y/o los
            Productos, su incumplimiento de estos Términos y/o su uso de
            cualquier sitio, servicio y/o producto de terceros. Si es residente
            de California, usted renuncia aquí a la Sección 1542 del Código
            Civil de California, que establece que: "Una liberación general no
            se extiende a las reclamaciones que el acreedor o la parte que
            libera no conoce o sospecha que existen a su favor en el momento de
            ejecutar la liberación, y que si los conociera habrían afectado
            materialmente su acuerdo con el deudor o la parte liberada". Si es
            residente de otra jurisdicción, usted renuncia aquí a cualquier
            estatuto o doctrina comparable. <br />
            <br />
            Renuncio a Juicio Colectivo y Acuerdo para arbitraje <br />
            <br />
            POR FAVOR LEA ESTA SECCIÓN DETENIDAMENTE. AFECTA DERECHOS QUE DE
            OTRA FORMA USTED TENDRÍA. PROPORCIONA LA RESOLUCIÓN DE DISPUTAS QUE
            USTED Y NOSOTROS PODRIAMOS TENER ENTRE NOSOTROS MEDIANTE EL USO DEL
            ARBITRAJE INDIVIDUAL, QUE ES DEFINITIVO Y VINCULANTE. EN EL
            ARBITRAJE INDIVIDUAL, NO HAY UN JUEZ O JURADO Y HAY MENOS
            INVESTIGACIÓN Y POTENCIAL PARA UN RECURSO QUE EN UN TRIBUNAL. ESTA
            CLÁUSULA DE ARBITRAJE SOBREVIVIRÁ A LA TERMINACIÓN DE ESTOS
            TÉRMINOS. CUALQUIER DISPUTA O RECLAMO REALIZADO POR USTED CONTRA
            NOSOTROS QUE SURJA DE O ESTÉ RELACIONADA CON ESTOS TÉRMINOS, EL
            SERVICIO Y/O SU COMPRA Y/O USO DE LOS PRODUCTOS, INDEPENDIENTEMENTE
            DE SI DICHA DISPUTA O RECLAMO SE BASA EN UN CONTRATO, UN DELITO
            CIVIL, RESPONSABILIDAD POR PRODUCTOS, ESTATUTO, FRAUDE,
            TERGIVERSACIÓN O CUALQUIER OTRA TEORÍA LEGAL (EN CONJUNTO UNA
            "DISPUTA") SE RESOLVERÁ A TRAVÉS DE UN ARBITRAJE INDIVIDUAL
            VINCULANTE, SEGÚN SE DESCRIBE A CONTINUACIÓN. <br />
            <br />
            O usted o nosotros tendremos el derecho de elegir iniciar un
            arbitraje vinculante para resolver cualquier Disputa mediante el
            envío de aviso por escrito de tal elección. Usted y nosotros
            acordamos y acordamos, además, tomar todos los pasos necesarios para
            renunciar al derecho de litigar cualquier Disputa en un tribunal, ya
            sea por vía de juicio en un tribunal, juicio por jurado o acción
            colectiva, y acordamos que: (1) dicho arbitraje estará gobernado por
            las Reglas de Arbitraje de Consumidores de la Asociación Americana
            de Arbitraje (las "Reglas"), que están disponibles aquí o llamando
            al +1-800-778-7879; (2) el arbitraje será llevado a cabo por un
            árbitro nombrado de acuerdo con las Reglas; (3) el idioma del
            arbitraje será el inglés; (4) el arbitraje se llevará a cabo cerca
            del lugar donde usted reside; (5) nosotros acordamos de forma
            irrevocable consentir y someter a la jurisdicción y lugar exclusivos
            personales a tal efecto con el fin de arbitrar cualquier acción; (6)
            el árbitro en tal arbitraje carecerá de jurisdicción para realizar
            un arbitraje de clase u otro procedimiento representativo, y no
            podrá consolidar los reclamos de una persona con otra; (7) todos los
            temas de aplicabilidad de esta cláusula de arbitraje, incluidos, sin
            limitación, los temas relacionados con el alcance, validez e
            inescrupulosidad, serán decididos por el árbitro; (8) la totalidad
            de cualquier arbitraje será confidencial, y ni usted ni nosotros
            tendremos derecho a divulgar la existencia, contenido o resultados
            de cualquier arbitraje, excepto en la medida en que así lo exija la
            ley o con fines de ejecución del laudo arbitral; (9) el pago de toda
            compensación, gastos y tarifas administrativas del árbitro (que
            incluyen, sin limitación, honorarios de presentación y audiencia)
            estará regido por las Reglas; (10) cada uno de nosotros asumirá los
            propios honorarios y costos relacionados con cualquier arbitraje,
            incluidos, sin limitación, los gastos de nuestros respectivos
            abogados, expertos, testigos y preparación y presentación de pruebas
            en el arbitraje; y (11) a pesar de (9) y (10) anteriores, el árbitro
            tendrá el derecho de reasignar su compensación, gastos y/o tarifas
            administrativas, así como sus honorarios y costos relacionados con
            el arbitraje, si determinan que una reclamación, defensa y/o
            excepción fue presentada con fines de acoso o es evidentemente
            temerario. <br />
            <br />
            Sea cual sea el arbitraje individual o en la corte, Usted y nosotros
            renunciamos cada uno al derecho de presentar una acción colectiva,
            acción colectiva, acción consolidada u otra acción representativa.
            Usted y nosotros podemos buscar el remedio solamente en nuestro
            propio nombre y solamente en la medida necesaria para remediar
            reclamos individuales. Esta renuncia a la acción colectiva es una
            parte material e esencial de esta disposición de arbitraje. Si esta
            disposición de arbitraje se determina como inválida, ilegal o
            inaplicable, se sustituirá una disposición modificada que lleve a
            cabo, lo más cercano posible, la intención original de Usted y
            nosotros como se evidencia únicamente por el lenguaje indicado
            arriba, y la validez, legalidad e aplicabilidad de cualquiera de las
            restantes disposiciones de estos Términos no será afectada o dañada
            de ninguna manera. Si por cualquier motivo esta disposición de
            arbitraje se considera inaplicable, inválida, ilegal o inaplicable,
            Usted y nosotros, en la medida máxima permitida por la ley,
            renunciamos cada uno a cualquier derecho a un juicio por jurado,
            cualquier derecho a recuperar daños punitivos o ejemplares y
            cualquier derecho a perseguir cualquier reclamo en una base
            colectiva, colectiva o consolidada o en una capacidad
            representativa. El fallo de cualquier decisión de arbitraje puede
            ser dictado en cualquier corte con jurisdicción apropiada. <br />
            <br />
            Misceláneos <br />
            <br />
            Reservamos el derecho de cambiar estos Términos en cualquier
            momento, efectivo inmediatamente después de publicar aquí. Cualquier
            actualización, nuevos servicios o modificaciones de los servicios
            existentes estarán regidos por estos Términos. El uso continuado de
            los Servicios tras la publicación de cambios a estos Términos
            constituye su aceptación de tales cambios. Le recomendamos
            encarecidamente que revise periódicamente estos Términos. Todos los
            precios y características de los Servicios y los Productos están
            sujetos a cambios sin previo aviso. Reservamos el derecho a
            modificar, interrumpir o suspender temporal o permanentemente,
            cualquiera de los Servicios (o parte de ellos) y/o los Productos,
            con o sin aviso. Usted acepta que no nos hacemos responsables ante
            usted ni ante terceros por cualquier modificación, interrupción o
            suspensión de cualquiera de los Servicios o los Productos. Cualquier
            comunicación que recibamos de usted se considerará NO confidencial
            (a excepción de la información que podamos acordar mantener
            confidencial de acuerdo con nuestra Política de Privacidad). Al
            enviarnos cualquier información (a excepción de información que
            podamos acordar mantener confidencial de acuerdo con nuestra
            Política de Privacidad) nos concede una licencia no exclusiva,
            mundial, libre de regalías, totalmente pagada, transferible,
            sublicenciable, perpetua, irrevocable para reproducir, distribuir
            copias de, preparar obras derivadas basadas en, públicamente
            realizar, mostrar públicamente y de otra forma usar y explotar esa
            información. <br />
            <br />
            Estos Términos constituyen el acuerdo completo entre usted y
            nosotros que rige su uso de los Servicios y los Productos. Si hay
            alguna discrepancia o inconsistencia entre estos Términos o
            cualquier otro término o condición disponible en otro lugar con
            respecto a los Servicios y / o los Productos, estos Términos regirán
            y tendrán precedencia. La renuncia a cualquier derecho bajo estos
            Términos no operará como renuncia pasada, presente o futura de ese
            derecho o de cualquier otro derecho. Ninguna renuncia será efectiva
            en ningún caso a menos que se reconozca y se acepte por escrito por
            nosotros. Si se determina que alguna disposición de estos Términos
            es inválida, ilegal o inaplicable, se sustituirá una disposición
            modificada que lleve a cabo, en la medida de lo posible, la
            intención original de usted y la nuestra, según lo evidencie
            únicamente el lenguaje de estos Términos, y la validez, legalidad y
            aplicabilidad de cualquiera de las demás disposiciones de estos
            Términos no se verán afectadas o dañadas de ninguna manera. No
            existe entre usted y nosotros ninguna empresa conjunta, asociación,
            empleo ni relación de agencia como resultado de estos Términos o del
            uso de los Servicios. Tenemos el derecho de asignar cualquiera o
            todos nuestros derechos y obligaciones bajo estos Términos en
            cualquier momento; sin embargo, todos los derechos que usted pueda
            tener bajo estos Términos o de otro modo en relación con los
            Servicios no pueden ser asignados por usted. Nada contenido en estos
            Términos se considerará como creador, o se interpretará como
            creador, de cualquier derecho de acción de beneficiario tercero
            sobre cualquier tercero, de ninguna manera. Los Servicios son
            ofrecidos por nosotros, y estamos ubicados en 116 Buck Creek Trail,
            Alva, WY, USA. Si usted es un residente de California, puede tener
            una copia de estos Términos enviada por correo electrónico enviando
            una carta a la dirección mencionada anteriormente con su dirección
            de correo electrónico y una solicitud para tales Términos. <br />
            <br />
            <br />
            <br />
            <br />
          </p>
        </div>
      </div>
    </>
  );
};

export default Terms;
