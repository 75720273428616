import React, {useState} from "react";

import "../../styles/footer2.scss";

import { Link } from "react-router-dom";

import axios from 'axios'

import LoadingButton from "../UX/LoadingButton";

import toast, { Toaster } from 'react-hot-toast';

const Footer = () => {

  const [email, setEmail] = useState('')

  const [isLoading, setIsLoading] = useState(false);

  const subscribeUser = () => {
    setIsLoading(true)
    if (email != "") {
      axios
        .post(axios.defaults.baseURL + "/subscribe", {
          email: email,
        })
        .then((res) => {
          if (res) {
            console.log(res);
            setIsLoading(false)
            toast.success('Gracias por suscribirte!')
          }
        })
        .catch((err) => {
          toast.error('Algo salio mal!')
          console.log(err)
        });
    }
    toast.error('Introduce un correo!')
    setIsLoading(false)
  };

  return (
    <>
    <Toaster position="bottom-center"/>
      <div className="footer">
        <div className="logo">
          <img className="logo" src="./assets/identity/Logo blanco-07.png" alt="" />
        </div>

        <div className="subform">
          <div id="subscribe" className="formSub">
            <label className="label-input"> Correo Electronico</label>
            <br />
            <input
              type="email"
              placeholder="johndoe@gmail.com"
              className="email-input"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <button className="submit-btn" onClick={subscribeUser}>
                {isLoading ? <LoadingButton /> : <>Suscribirse</>}
              </button>
              <h4 className="subtitle">
              Al suscribirte estas aceptando nuestros
              <Link className="link" to="/terms"> Terminos y condiciones </Link>
               y <Link className="link" to="/privacy-policy">
                {" "}
                Politicas de Privacidad
              </Link>
              .
            </h4>
          </div>
        </div>

        {/* <div className="footer-content">
          <div>
            <span className="foo" style={{ color: "#f1f2f6", fontWeight: "700" }}>
              Adres do korespondencji:
            </span>
            <p
              style={{
                color: "#f1f2f6",
                marginTop: "1.5rem",
                textAlign: "justify",
                width: "fit-content",
              }}

              className="foo"
            >
              Kancelaria Transportowa ITD-PIP ul. Kobierzycka 10 52-315 Wrocław,
              Polska mail: biuro@itd-pip.p
            </p>
          </div>
          <div>
            <span className="foo" style={{ color: "#f1f2f6", fontWeight: "700" }}>
              Dane rejestrowe:
            </span>
            <p
              style={{
                color: "#f1f2f6",
                marginTop: "1.5rem",
                marginRight: "2em",
                textAlign: "justify",
              }}

              className="foo"
            >
              Kancelaria Transportowa ITD-PIP Sp. z o.o. S.K. ul. Kobierzycka 10
              (II piętro) 52-315 Wrocław, Polska
            </p>
          </div>
          <div>
            <span className="foo" style={{ color: "#f1f2f6", fontWeight: "700" }}>
              Adres do korespondencji:
            </span>
            <p
              style={{
                color: "#f1f2f6",
                marginTop: "1.5rem",
                textAlign: "justify",
              }}

              className="foo"
            >
              Kancelaria Transportowa ITD-PIP ul. Kobierzycka 10 52-315 Wrocław,
              Polska mail: biuro@itd-pip.p
            </p>
          </div>
          <div>
            <span className="foo" style={{ color: "#f1f2f6", fontWeight: "700" }}>
              Dane rejestrowe:
            </span>
            <p
              style={{
                color: "#f1f2f6",
                marginTop: "1.5rem",
                textAlign: "justify",
              }}

            >
              Kancelaria Transportowa ITD-PIP Sp. z o.o. S.K. ul. Kobierzycka 10
              (II piętro) 52-315 Wrocław, Polska
            </p>
          </div>
        </div> */}

        <div className="footer-icons"></div>

        <div className="footer-copy">
          <p>
            @2022 - Descubri Todo
          </p>
        </div>

        {/* <div id="subscribe" className="formSub">
            <input type="email" placeholder="Correo Electronico" className="email-input" />
            <button type="submit" className="submit-btn">Suscribirse</button>
            <h4 className="subtitle">
              By subscribing you accept our Privacy policy terms and conditions.
            </h4>
          </div> */}
      </div>
    </>
  );
};

export default Footer;
