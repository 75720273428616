import React from "react";
import LandingSub from "../../components/LandingComponents/LandingSub";
import Recommended from "../../components/LandingComponents/Recommended";
import Community from "../../components/LandingComponents/Community";
import Facts from "../../components/LandingComponents/Facts";
import Carrousel from "../../components/LandingComponents/Carroussel";
import Footer from "../../components/LandingComponents/Footer";

import '../../styles/shared.scss'

const Landing = () => {
  //Get the button:
  const mybutton = document.getElementById("myBtn");

  // When the user scrolls down 20px from the top of the document, show the button
  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    // if (
    //   document.body.scrollTop > 20 ||
    //   document.documentElement.scrollTop > 20
    // ) {
    //   mybutton.style.display = "block";
    // } else {
    //   mybutton.style.display = "none";
    // }
  }

  // When the user clicks on the button, scroll to the top of the document
  function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  return (
    <>
      <LandingSub></LandingSub>
      <Recommended></Recommended>
      <Community></Community>
      <Facts></Facts>
      <Carrousel></Carrousel>
      <Footer></Footer>
{/* 
      <button onClick={topFunction} id="myBtn" title="Go to top">
        Top
      </button> */}
    </>
  );
};

export default Landing;
