import React, { useEffect, useState } from "react";

import "../../styles/recommended.scss";
import RecommendedCard from "../RecommendedCard";

import { Parallax } from "react-scroll-parallax";

import axios from "axios";

const Recommended = () => {
  const [mainPost, setMainPost] = useState(null);

  useEffect(() => {
    axios.get(axios.defaults.baseURL + "/mainpost").then((res) => {
      // console.log(res);

      setMainPost(res.data[0]);
    });
  }, []);

  return (
    <>
      <div className="container-Recommended desktop">
        {/* <Parallax translateY={[-50, 50]}> */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "14rem",
          }}
        >
          <h1 className="presentation">
            La mejor guia de viajes{" "}
            <span style={{ color: "#FF813B" }}>
              <br />
              para ti.
            </span>
          </h1>
        </div>

        <div className="recommended-stuff">
          <div class="containerRec">
            <div class="title">
              <p className="parr-rec">
                En este blog podrás encontrar todo lo que necesitas saber para
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  elegir tu próximo destino
                </span>
                , desde consejos y organización, hasta itinerarios y
                presupuestos. Podrás descubrir lugares recónditos del planeta o
                elegir destinos más clásicos. También dependiendo de tus
                intereses podrás navegar por las distintas paradas del blog:{" "}
                <span style={{ fontWeight: "bold" }}>
                  Vacaciones, Turismo Sustentable y Nuestras Experiencias.{" "}
                </span>
                Estamos comprometidos a agregar valor y Descubrir todo un mundo
                nuevo en comunidad.
              </p>
            </div>
            <div class="recommended">
              <div className="centrar">
                <RecommendedCard></RecommendedCard>
              </div>
            </div>
            {/* <div class="par">
            
          </div>
          <div class="recommended-card">
            
          </div> */}
          </div>
        </div>
        {/* </Parallax> */}
      </div>

      {/* <div className="container-Recommended mobile">
        <Parallax translateY={[-15, 30]}>
          <div className="recommended-stuff">
            <div class="containerRec">
              <div class="title">
                <h1 className="presentation">La mejor guia de viajes para ti.</h1>
                <p className="parr-rec">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. In id
                  tincidunt nibh. Cras tortor tortor, tincidunt rhoncus finibus
                  ac, ultricies sagittis magna. Aliquam sodales venenatis risus
                  ultricies vulputate. In congue magna non neque rhoncus auctor.
                  Aenean non sodales ante. Nunc efficitur vestibulum lectus quis
                  fermentum.{" "}
                </p>
              </div>
              <div class="recommended">
                <h1>Recommended</h1>
                <div className="centrar">
                  <RecommendedCard></RecommendedCard>
                </div>
              </div>
             
            </div>
          </div>
        </Parallax>
      </div> */}
    </>
  );
};

export default Recommended;
