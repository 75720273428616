import React from "react";
import "./loading2.css";


const LoadingButton = () => {
  return (
    <div
      style={{
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        
      }}
    >
      <div className="four-dots" style={{color:"white"}}></div>
    </div>
  );
};

export default LoadingButton;
