import React from "react";
import "./loading.css";

const Loading = () => {
  return (
    <div
      style={{
        position:"fixed",
        top:"0%",
        left:"0%",
        width:'100vw',
        height:"100vh",
        backgroundColor:"#f1f2f6"
      }}
    >
      <div style={{position:"fixed", top:"50%", left:"50%"}} className="four-dots"></div>
    </div>
  );
};

export default Loading;
