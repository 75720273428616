import React from "react";
import { Carousel } from "react-carousel-minimal";

import "../../styles/carroussel.scss";

import { Link } from "react-router-dom";

const Carroussel = () => {
  const data = [
    {
      image: "./assets/juli-kosolapova-SP5CNbsdHmM-unsplash.jpg",
    },
    {
      image: "./assets/pexels-riccardo-307008.jpg",
    },
    {
      image: "./assets/pexels-asad-photo-maldives-1024986.jpg",
    },
    {
      image: "./assets/pexels-mati-mango-11481626.jpg",
    },
  ];

  const data2 = [
    {
      image: "./assets/mobile/dario-bronnimann-tu2apPbNQTs-unsplash.jpg",
    },
    {
      image: "./assets/mobile/kevin-clyde-berbano-RJxrLunEq0I-unsplash.jpg",
    },
    {
      image: "./assets/mobile/vincent-stuber-kppu3rl5neM-unsplash (1).jpg",
    },
    {
      image: "./assets/mobile/shea-rouda-mRQk1B4OM0k-unsplash (1).jpg",
    },
  ];

  const captionStyle = {
    fontSize: "2em",
    fontWeight: "bold",
    backGroundColor: "red",
  };

  return (
    <>
      <div
        style={{ textAlign: "center", position: "absolute" }}
        className="containerCarr"
      >
        {/* <h2>React Carousel Minimal</h2> */}
        {/* <p>Easy to use, responsive and customizable carousel component for React Projects.</p> */}
        <div
          className="hide-mobile"
          style={
            {
              // padding: "0 20px"
            }
          }
        >
          <Carousel
            className="carroussel"
            data={data}
            time={4000}
            width="100%"
            height="100%"
            captionStyle={captionStyle}
            captionPosition="center"
            automatic={true}
            pauseIconColor="white"
            pauseIconSize="40px"
            slideBackgroundColor="darkgrey"
            slideImageFit="contain"
            style={{
              textAlign: "center",
              maxWidth: "100vw",
              maxHeight: "50vh",
              // margin: "40px auto",
            }}
          />
        </div>

        <div className="is-mobile"></div>

        <div className="text">
          <img
            className="logotipo-blog"
            src="./assets/identity/Logo blanco-04.png"
            alt=""
          />
          <div className="line-car"></div>
          <p className="par-car">
            <span style={{fontWeight:"bold"}}>¿Estás en búsqueda de nuevos destinos?</span><br/> Te proponemos descubrir
            innumerables propuestas de viajes, lugares remotos en el mundo. En
            este blog vas a encontrar contenido informativo, didáctico y
            práctico para que elijas tu próximo destino y puedas cumplir tu
            sueño.
          </p>
          <Link to="/blog">
            <button className="button-blog">Explora nuestro Blog</button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Carroussel;
