import React from "react";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";

import { Link, Outlet } from "react-router-dom";

import ArticleCard from "../../components/BlogComponents/ArticleCard";

import Categories from "../../components/BlogComponents/Categories";

import Box from "@mui/material/Box";

import Grid from "@mui/material/Grid";

import Article from "../../components/BlogComponents/Article";

import "../../styles/blog.scss";

import "../../styles/categories.scss";

import Switch from "@mui/material/Switch";
import Paper from "@mui/material/Paper";
import Zoom from "@mui/material/Zoom";
import FormControlLabel from "@mui/material/FormControlLabel";

import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import Loading from "../../components/UX/Loading";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";

import Footer2 from "../../components/LandingComponents/Footer2";

const Blog = () => {
  const [articles, setArticles] = useState([]);

  const [mainPost, setMainPost] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  const getArticles = async () => {
    setIsLoading(!isLoading);
    await axios.get(axios.defaults.baseURL + "/posts/descubritodo").then((res) => {
      setArticles(res.data);
      setIsLoading(!isLoading);
    });
  };

  useEffect(() => {
    getArticles();
  }, []);

  useEffect(() => {
    axios.get(axios.defaults.baseURL + "/mainpost").then((res) => {
      // console.log(res);

      setMainPost(res.data[0]);

      console.log(mainPost);
    });
  }, []);

  const renderArticle = (article) => {
    return (
      <>
        <ArticleCard article={article}></ArticleCard>
      </>
    );
  };

  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const icon = (
    <Paper sx={{ m: 1 }} elevation={4}>
      <Box component="svg" sx={{ width: 100, height: 100 }}>
        <Box
          component="polygon"
          sx={{
            fill: (theme) => theme.palette.common.white,
            stroke: (theme) => theme.palette.divider,
            strokeWidth: 1,
          }}
          points="0,100 50,00, 100,100"
        />
      </Box>
    </Paper>
  );

  const renderArticlesDisplay = (art) => {
    console.log(art);
    return (
      <>
        <div className="display-articles">
          <div className="row">
            <div className="">
              <LazyLoadImage
                className="image-article"
                effect="opacity"
                src={art.mainPhoto.payload} // use normal <img> attributes as props
                placeholderSrc={art.mainPhoto.payload}
              />
              {/* <img className="image-article" src={art.mainPhoto.payload}></img> */}
            </div>
            <div className="description-display">
              <div className="art-title">{art.title}</div>
              <div className="art-category">{art.category[0].name}</div>
              <div className="art-author">{art.author}</div>
              <Link to={`/posts/${art._id}`} style={{ textDecoration: "none" }}>
                <div className="icon-add">
                  <AddCircleOutlineRoundedIcon style={{ color: "#FF813C" }} />
                  <span className="art-text">Leer Mas</span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {isLoading && <Loading></Loading>}
      {mainPost != null && (
        <div className="blog-container">
          {/* <div>
            <div className="blog-headline">
              <img
                src={mainPost.mainPhoto.payload}
                className="background-mainPhoto-blog"
              ></img>
              <img
                className="post-title-blog"
                src="./assets/identity/Logo blanco-04.png"
                alt=""
              />
            </div>

            
          </div> */}

          <div className="title-blog">
            {" "}
            Nuestros <span style={{ color: "#FF813C" }}>Blogs.</span>{" "}
          </div>

          <p className="presentation-parr">
            Descubrí todo lo que hay para conocer: tierras remotas, reinos
            antiguos, ciudades modernas, parques naturales, monumentos
            históricos y patrimonios. Si todavía no te decidiste a dónde ir,
            estás en el lugar correcto. En este blog podrás encontrar contenido
            informativo y práctico para que elijas tu próximo destino. Tus
            vacaciones soñadas están a una sola lectura.
          </p>

          {/* <h1 className="nuestros-blogs">Recomendado</h1> */}
          <div className="layout-container">
            <div>
              {articles.map((art, index) => {
                if (index <= 2) {
                  return renderArticlesDisplay(art, index);
                }
              })}
            </div>
            <div className="main">
              <p className="mainPost-category">{mainPost.category[0].name}</p>
              <div className="lineMainPost"></div>
              <img
                className="mainpost-layout"
                src={mainPost.mainPhoto.payload}
                alt=""
              />
              {/* <div style={{ marginTop: "1rem", marginLeft: "0.5rem" }}> */}
                <Link
                  to={`/posts/${mainPost._id}`}
                  style={{ textDecoration: "none", cursor: "pointer" }}
                >
                  <h2 className="title-layout">{mainPost.title}</h2>
                </Link>
                <p className="mainpost-author">{mainPost.author}</p>
              {/* </div> */}
            </div>
          </div>

          <div className="nuestros-blogs">
            <h2 className="nuestros-h">Descubri todo con nosotros.</h2>

            <div className="line-big"></div>
          </div>

          <Box sx={{ flexGrow: 1, width: "60vw" }}>
            <Grid container justifyContent="center" spacing={3}>
              {Array.from(articles).map((art, index) => {
                if (index <= 5) {
                  return (
                    <Grid
                      item
                      xs="auto"
                      sm="auto"
                      md="auto"
                      lg="auto"
                      key={index}
                    >
                      {renderArticle(art)}
                    </Grid>
                  );
                }
              })}
            </Grid>
          </Box>

          <Categories></Categories>

          {/* <div className="categories">
            <h1>TURISMO SUSTENTABLE</h1>
            <h1>MIS EXPERIENCIAS</h1>
            <h1>VACACIONES</h1>

          </div> */}
        </div>
      )}
      <Footer2 style={{position:"absolute"}}></Footer2>
    </>
  );
};

export default Blog;
