import React from "react";

import { Parallax } from "react-scroll-parallax";

import "../../styles/community.scss";

const Community = () => {
  return (
    <>
      <div className="community">
        <div className="line-com"></div>
        <div id="container" className="containerCom">
            <div>
              <h1 className="title-community">
                Construyendo una
                <br />
                <span className="span-title">Comunidad</span>
              </h1>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="layout">
                <div className="main-photo">
                  <img
                    className="main-photo"
                    src="./assets/juli-kosolapova-SP5CNbsdHmM-unsplash.jpg"
                    alt=""
                  />
                </div>
                <div className="secondary-photo">
                  <div style={{ marginBottom: "1em" }}>
                    <img
                      className="secondary-photo"
                      src="./assets/pexels-riccardo-307008.jpg"
                      alt=""
                    />
                  </div>
                  <div>
                    <img
                      className="secondary-photo"
                      src="./assets/adam-navarro-qXcl3z7_AOc-unsplash.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="text-mobile">
              <p className="parr">
                Somos un grupo de personas que comparte la misma pasión: viajar
                por el mundo. Compartimos experiencias y datos
                relevantes de lugares para formar una comunidad viajera.
                Descubrirás herramientas prácticas y guías de viaje para
                simplificar tu destino y elegir tus próximas vacaciones.
              </p>
            </div>
        </div>
      </div>
    </>
  );
};

export default Community;
