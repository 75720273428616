import React from "react";

import axios from "axios";

import "../../styles/blog.scss";

import { Link, Outlet } from "react-router-dom";
import { useState } from "react";

const ArticleCard = (article) => {
  // const [mainPhoto, setMainPhoto] = useState(article.article.mainPhoto[0].payload)

  const [mainPhoto, setMainPhoto] = useState(
    "https://i.ytimg.com/vi/mlBZeNKCbSI/maxresdefault.jpg"
  );

  //i.ytimg.com/vi/mlBZeNKCbSI/maxresdefault.jpg

  https: return (
    <>
      {console.log(article.article.mainPhoto.payload)}
      <Link className="card-title" to={`/posts/${article.article._id}`}>
        {article.article.mainPhoto.payload && (
          <>
            {/* {article.article.mainPhoto[0].payload} */}

            {/* {console.log(mainPhoto)} */}

            <div className="card">
              <img
                src={article.article.mainPhoto.payload}
                alt=""
                className="image"
              />

              <h2 className="card-title">{article.article.title}</h2>
              <button className="leer-mas">Leer mas</button>
            </div>
          </>
        )}

        {/* {console.log(article.article.mainPhoto[0].payload)} */}
      </Link>
      <Outlet />

      {/* foto de portada del articulo */}
      {/* <div>{article.article.}</div> */}
    </>
  );
};

export default ArticleCard;
