import React from "react";

import "../styles/privacyAndTerms.scss";

const PrivacyPolicy = () => {
  return (
    <>
      {/* <div className="container">


        
    </div> */}
      <div className="headline">
        <h1 className="title-PP">Politicas de Privacidad</h1>
      </div>

      <div className="text-PP">
        <div>
          <h3 className="subtitle-PP">Politicas de privacidad</h3>

          <p className="par-PP" style={{ textAlign: "justify" }}>
            Política de privacidad La Política de Privacidad ha sido actualizada
            al 20/01/22. Para obtener detalles sobre el ejercicio de los
            derechos en virtud de la Ley de Privacidad del Consumidor de
            California, haga clic aquí en la sección 7.<br/> LEA ESTA POLÍTICA DE
            PRIVACIDAD (ESTA "POLÍTICA DE PRIVACIDAD") ANTES DE UTILIZAR ESTE
            SITIO WEB, APLICACIÓN O CUALQUIER PRODUCTO O SERVICIO Descubri Todo<br/>
            Si tiene un acuerdo por escrito por separado con Descubri Todo, las
            actualizaciones de los Términos de servicio no se aplicarán a usted,
            pero las actualizaciones de la Política de uso aceptable entrarán en
            vigencia después del período de aviso de actualización proporcionado
            en nuestro acuerdo por escrito por separado.<br/> <br/>Política de privacidad
            de Descubri Todo <br/>El sitio web de Descubri Todo actualmente ubicado
            en DescubriTodo.com, y los subdominios de ese sitio web, donde se
            publica esta Política de privacidad (colectivamente, el "Sitio web")
            son puestos a disposición por Descubri Todo en su nombre
            (colectivamente, "DescubriTodo.com" , “nosotros”, “nos” o
            “nuestro”). Estamos encantados de interactuar con usted a través de
            su uso del sitio web y a través de nuestros diversos servicios y
            aplicaciones en línea proporcionados por DescubriTodo.com a través
            del sitio web (colectivamente, los "Servicios"). Esta política (esta
            "Política de privacidad") tiene como objetivo ayudarlo a comprender
            nuestras prácticas con respecto a la recopilación, el uso y la
            divulgación de información personal en relación con estos Servicios,
            así como informarle sobre sus derechos y opciones sobre el uso de su
            información. No usaremos, divulgaremos ni compartiremos su
            información excepto como se describe en esta Política de privacidad.<br/><br/>
            Esta Política de privacidad se aplicará a los visitantes del sitio
            web y a los usuarios de cualquier servicio. Recuerde que acepta las
            prácticas y los procedimientos descritos en esta Política de
            privacidad y los Términos de servicio de DescubriTodo.com cuando
            utiliza los Servicios. Si proporciona información personal, también
            acepta que esa información puede transferirse desde su ubicación
            actual a las oficinas y servidores de DescubriTodo.com y sus
            terceros autorizados. Si no está de acuerdo con esta Política de
            privacidad y los Términos de servicio, no use el sitio web ni ningún
            servicio.<br/><br/> 1. Tipos de información que recopilamos 2. Cómo
            recopilamos su información 3. Cómo usamos su información 4. Cómo
            podemos compartir su información 5. Servicios de comunicación y
            características de la comunidad 6. Publicidad de terceros 7. Todos
            los usuarios: sus opciones y controles 8. Residentes de California:
            Derechos de privacidad de California 9. Prácticas de seguridad 10.
            Enlaces a otros servicios 11. Información Adicional 12. Cambios a
            esta Política de Privacidad 13. Datos de los niños 14. Exclusiones
            15. Contáctenos<br/><br/> 1. Tipos de información que recopilamos Cuando
            interactúa con nosotros a través de los Servicios, recopilamos la
            información que proporciona, la información de uso y la información
            sobre su dispositivo. También recopilamos información sobre usted de
            otras fuentes, como proveedores de servicios y programas opcionales
            en los que participa, que podemos combinar con otra información que
            tenemos sobre usted. Estos son los tipos de información que
            recopilamos sobre usted: <br/><br/>(A) Información personal<br/><br/> “Información
            personal” es información que identifica, se relaciona con, describe
            o puede vincularse razonablemente, directa o indirectamente, a un
            individuo u hogar en particular. Podemos recopilar Información
            personal (como lo hemos hecho durante los últimos doce meses) de
            usted en o a través de los Servicios de varias maneras, incluso
            cuando se registra en el sitio web, compra un Servicio, participa en
            concursos, encuestas o juegos en línea. , regístrese para recibir un
            boletín o suscripción, o compre mercadería. También podemos, de vez
            en cuando, recibir Información personal sobre usted de fuentes de
            terceros para mejorar los Servicios y para los diversos fines
            descritos en esta Política de privacidad.<br/><br/> Hemos agrupado las
            siguientes categorías de Información personal para explicar cómo
            usamos este tipo de información. Estos términos se utilizan a lo
            largo de esta Política de privacidad: <br/><br/>Identificadores personales,
            incluidos su nombre y apellido, fecha de nacimiento, edad, sexo,
            nombre de usuario. <br/><br/>Información de contacto, incluida su dirección de
            correo electrónico, número de teléfono y domicilio. <br/><br/>(B) Información
            identificable del dispositivo Podemos recopilar información que
            puede no identificarlo a usted o a un hogar personalmente de manera
            razonable, pero está vinculada a su computadora o dispositivo
            ("Información identificable del dispositivo"). Recopilamos
            información identificable del dispositivo de usted en el curso
            normal de la prestación de los Servicios. Cuando visita nuestro
            sitio web para navegar, leer o descargar información, recopilamos
            automáticamente información sobre su computadora que envía su
            navegador, como su dirección IP, tipo e idioma del navegador,
            tiempos de acceso, páginas visitadas y direcciones de sitios web de
            referencia. Podemos usar la Información identificable del
            dispositivo para analizar tendencias, ayudar a administrar los
            Servicios, rastrear los movimientos en línea de los usuarios,
            conocer y determinar cuánto tiempo pasan los visitantes en cada
            página del sitio web, cómo navegan los visitantes por el sitio web o
            usan otros Servicios, y recopilar información demográfica amplia
            para uso agregado. También podemos recopilar información
            identificable del dispositivo a través de "cookies" o "balizas web",
            como se explica a continuación. <br/><br/>Además, podemos recopilar
            información sobre su dispositivo móvil, como el tipo y modelo, el
            sistema operativo (p. ej., iOS o Android), el nombre del operador,
            el navegador móvil (p. ej., Chrome, Safari), las aplicaciones que
            utilizan nuestro sitio web y los identificadores asignados a su
            dispositivo. , como su identificador de publicidad de iOS ("IDFA"),
            la identificación de publicidad de Android ("AAID") o el
            identificador de dispositivo único (un número que el fabricante de
            su dispositivo le da de manera exclusiva a su dispositivo), a veces
            denominado ID de operador de telefonía móvil.<br/><br/> También podemos
            recopilar su información de ubicación, como su código postal o el
            área geográfica aproximada proporcionada por su proveedor de
            servicios de Internet ("ISP") o la información de posicionamiento de
            ubicación proporcionada por los servicios de ubicación y las
            funciones de GPS de su dispositivo móvil cuando los servicios de
            ubicación han sido activado.<br/><br/> Cuando la información agregada o
            anonimizada, incluida la información identificable del dispositivo,
            se asocia directamente con la información personal, de modo que la
            información agregada o anonimizada pueda asociarse razonablemente
            con un usuario individual u hogar en particular, entonces esta
            información agregada o anonimizada es tratados como información
            personal.<br/><br/> 2. Cómo recopilamos su información<br/><br/> (A) Cookies y balizas
            <br/><br/> web Para ayudarnos a brindarle un mejor servicio, podemos recopilar
            información identificable del dispositivo de usted a través de
            tecnologías como "cookies" y "balizas web" (que también se denominan
            GIF transparentes, etiquetas de píxeles o píxeles) o mediante el
            seguimiento del protocolo de Internet ("IP" ) direcciones.<br/><br/> Las
            "cookies" son pequeños archivos de datos que su navegador web
            almacena cuando interactúa con un sitio web. Cuando visita una
            página web, la cookie devuelve información identificable del
            dispositivo.<br/><br/> Las "balizas web" son pequeñas imágenes gráficas
            (generalmente invisibles) que se pueden incrustar en el contenido y
            los anuncios en una página web que rastrean el uso del sitio web y
            la efectividad de la comunicación. Estas balizas web se pueden usar
            para reconocer nuestras cookies y monitorear ciertas interacciones
            del usuario con un sitio web.<br/><br/> Las cookies funcionan en segundo plano
            y puede desactivarlas ajustando la configuración de su navegador
            web, pero hacerlo puede dificultar el uso de algunas de las
            funciones del sitio web o las funciones pueden no estar disponibles
            para usted. Podemos utilizar cookies para facilitar su experiencia
            en el sitio web, por ejemplo, recordando sus preferencias, haciendo
            un seguimiento de su nombre de usuario, contraseña y, si desea
            comprar cosas mientras está en el sitio web, su "carrito de
            compras".<br/><br/> También podemos usar cookies y otras herramientas de
            seguimiento de actividad en el sitio web y a través de otros
            servicios para recopilar sus métricas de participación, incluidos
            sus identificadores personales e información identificable del
            dispositivo, en relación con su actividad en el sitio web y los
            servicios.<br/><br/> Para obtener más información sobre sus opciones con
            respecto a las cookies, puede revisar la guía de la FTC para
            excluirse del seguimiento en línea para computadoras y dispositivos
            móviles. También puede ver la sección "Todos los usuarios: sus
            opciones y controles" a continuación para conocer las formas de
            darse de baja. <br/><br/>Su navegador puede ofrecerle una opción de "No
            rastrear", que le permite señalar a los operadores de sitios web y
            aplicaciones y servicios web que no desea que rastreen sus
            actividades en línea. El sitio web y los servicios actualmente no
            admiten solicitudes de No rastrear en este momento. <br/><br/>(B) Otras
            interacciones directas con usted<br/><br/> Usted nos proporciona su
            información en sus interacciones directas con nosotros. Recopilamos
            la información que nos proporciona cuando registra cuentas en
            nuestro sitio web y otros servicios; solicitarnos entradas,
            boletines y otros productos, servicios o información; responder
            encuestas; participar en sorteos o concursos; o interactuar con
            nosotros de otra manera. Esta información incluye identificadores
            personales, información de contacto, información biográfica,
            información financiera e información de identificación del
            dispositivo, que usted nos proporciona de vez en cuando.<br/><br/> A excepción
            de las direcciones IP, podemos hacer coincidir la Información
            identificable del dispositivo con la Información personal que
            podamos tener de usted o sobre usted, lo que nos permitiría
            identificarlo. Podemos usar esta información para brindarle ofertas
            que están disponibles donde se encuentra físicamente. <br/><br/>(C) Redes
            sociales<br/><br/> Al usar los Servicios o al usar ciertos servicios de redes
            sociales de terceros (por ejemplo, Facebook, Twitter) (cada uno de
            ellos una "Red social"), puede tener la opción de conectar la
            información y la actividad de su Servicio con la información y la
            actividad de su Red social. Si autoriza dicha conexión, nos permite
            compartir o publicar información sobre la actividad de su Servicio
            con esa Red social y sus usuarios, y acceder a cierta información de
            su Red social, tal como se le reveló en el momento en que dio su
            consentimiento para la conexión. La información que podemos
            compartir con una red social puede incluir información técnica sobre
            la actividad de su Servicio, sus comentarios sobre el Servicio o los
            videos que ve en el Servicio. La información a la que podemos
            acceder sobre usted, con su consentimiento, desde una red social
            puede incluir, entre otros, su información básica de la red social,
            sus datos de ubicación, su lista de contactos, amigos o seguidores y
            cierta información sobre sus actividades en la red social. Red
            social. Si permite una conexión entre una Red social y un Servicio,
            nosotros (y esa Red social) podemos identificarlo y asociar la
            información recibida de conformidad con la conexión con la
            información que ya tenemos sobre usted. Para obtener más
            información, revise las divulgaciones de privacidad y los términos
            de su cuenta de red social, que rigen cómo se recopila y comparte
            con nosotros la información de esa cuenta.<br/><br/> (D) Otras fuentes de
            terceros<br/><br/> DescubriTodo.com también puede recibir otra información de
            los Proveedores de servicios en relación con la operación de
            nuestros Servicios, incluso cuando compra nuestros boletos o realiza
            nuestras encuestas a través de un proveedor externo. También podemos
            recibir información de proveedores de servicios a través de estudios
            demográficos y de marketing que utilizamos para complementar la
            información personal y la información anonimizada proporcionada
            directamente por usted.<br/><br/> 3. Cómo usamos su información<br/><br/> Para el
            propósito recopilado: si proporciona información personal por un
            motivo determinado, utilizaremos la información personal en relación
            con el motivo por el cual se proporcionó. Por ejemplo, si realiza
            una compra en línea, utilizaremos la información de su tarjeta de
            crédito para completar y procesar sus compras. Además, si se
            registra o abre una cuenta en el sitio web, la información personal
            que proporcione puede usarse para mantener su cuenta, brindarle
            acceso a ciertas funciones de nuestro sitio web u ofrecerle los
            beneficios y privilegios que normalmente acompañan al registro.
            Estos beneficios pueden incluir acceso y uso de contenido o
            actividades exclusivas o personalizadas (como tableros de mensajes)
            y participación en eventos y promociones especiales (como concursos
            y sorteos). Como parte de estas actividades, podemos usar su
            información personal para determinar su elegibilidad, notificarle si
            es un ganador y/o cumplir y entregar premios.<br/><br/> Soporte de proveedores
            de servicios: además, la información personal y la información
            identificable del dispositivo que recopilamos pueden ser utilizadas
            por nosotros y por proveedores de servicios externos (como lo ha
            sido durante los últimos doce meses), como proveedores de
            alojamiento, empresas de gestión de datos, lista de direcciones
            empresas de alojamiento, proveedores de servicios de correo
            electrónico, empresas de análisis (p. ej., Google Analytics),
            empresas de distribución, empresas de cumplimiento y procesadores de
            pagos (colectivamente, "Proveedores de servicios") para una variedad
            de propósitos, que incluyen: <br/>- contactarlo sobre el sitio web y/o
            los servicios que ha solicitado;<br/> - proporcionarle los Servicios o la
            información que ha solicitado, o los productos o servicios que ha
            pedido o en los que se ha registrado, como boletines informativos;<br/> -
            confirmar o completar un pedido que haya realizado; <br/>- administrar su
            participación en un concurso, sorteo, promoción, encuesta o encuesta
            de votación;<br/> - facilitar la operación, el mantenimiento y la mejora
            de los Servicios, nuestras operaciones comerciales y el
            mantenimiento de registros internos; y <br/>- con su permiso, enviarle
            material promocional u ofertas especiales de la MLS, los Clubes
            Miembros, nuestros socios comerciales u otros terceros. Si ya no
            desea recibir nuestros correos electrónicos promocionales, o
            compartir su información con terceros como se describió
            anteriormente, puede cambiar de opinión y/o sus preferencias
            siguiendo las instrucciones en "Todos los usuarios: sus opciones y
            controles". ” de la Política de privacidad a continuación. <br/><br/>Nuestros
            Proveedores de servicios reciben la información que necesitan para
            realizar sus funciones designadas en relación con los Servicios, y
            no los autorizamos a usar o divulgar su Información personal para su
            propio marketing u otros fines.<br/><br/><br/> Verificación de identidad y
            seguridad: también podemos obtener información sobre usted de otras
            empresas para verificar su identidad para que podamos procesar
            adecuadamente sus solicitudes y prevenir el fraude. Al acceder y
            utilizar nuestros Servicios, usted acepta que podemos utilizar su
            Información personal enviada y obtenida y la Información
            identificable del dispositivo para tales fines y para mantener la
            seguridad de los Servicios.<br/><br/><br/> Mejora del producto y marketing: es
            posible que DescubriTodo.com también use su información personal,
            información identificable del dispositivo y otra información
            personal no identificable recopilada a través del sitio y los
            servicios para ayudarnos a mejorar el contenido y la funcionalidad
            del sitio y los servicios para comprender mejor a nuestros usuarios.
            y para brindar atención al cliente, detectar, personalizar el
            control y las experiencias en nuestros Sitios y aplicaciones, y
            mejorar los Servicios. DescubriTodo.com puede usar esta información
            para comunicarse con usted con respecto al sitio web y los servicios
            y, en la medida permitida por la ley, para informarle sobre los
            servicios que creemos que serán de su interés.<br/><br/> Cumplimiento y
            Obligaciones Legales: DescubriTodo.com puede usar su Información
            Personal para hacer cumplir nuestros Términos de Servicio, o
            investigar y prevenir actividades que puedan violar nuestras
            políticas o ser ilegales, y para responder a procedimientos y
            obligaciones legales.<br/><br/> 4. Cómo podemos compartir su información<br/><br/> (A)
            Transferencias comerciales<br/><br/> A medida que desarrollamos nuestro
            negocio, podemos vender o comprar negocios o activos, como la venta
            de todo o la mayor parte de DescubriTodo.com o acciones a otra
            empresa. En el caso de una venta corporativa, fusión,
            reorganización, disolución o evento similar en el que un tercero
            asuma el control de todo o parte de nuestro negocio, la Información
            personal puede ser parte de los activos o negocios transferidos.<br/><br/> (B)
            Proveedores de servicios y terceros<br/><br/> Compartimos información personal
            e información identificable del dispositivo con proveedores de
            servicios, como se describe anteriormente en "Cómo usamos su
            información". No vendemos su información personal a terceros, ya sea
            a cambio de dinero u otra consideración valiosa.<br/><br/> Podemos compartir
            con terceros información demográfica agregada recopilada en o a
            través de los Servicios para diversos fines, como investigación de
            fanáticos y análisis de audiencia. La información demográfica
            agregada no incluye y no está vinculada a ninguna Información
            personal que pueda identificarlo a usted o a otra persona
            individual, pero podemos usar sus direcciones IP para verificar las
            ubicaciones de los usuarios para diversos fines, incluidas las
            restricciones de bloqueo.<br/><br/><br/> (C) Comercializadores de terceros<br/><br/> Con su
            permiso, podemos compartir su Información personal con nuestros
            socios de marketing y otros terceros para que puedan comunicarse con
            usted directamente con respecto a promociones u ofertas especiales
            (como suscripciones y promociones de bienes y servicios, incluso,
            por ejemplo, a través de la participación en un sorteo o concurso
            copatrocinado). No somos responsables de cómo estos terceros puedan
            usar su información personal, y dichos usos están sujetos a sus
            propias políticas. Si ha dado su consentimiento para compartir su
            información personal con terceros para fines de marketing de dichos
            terceros, puede cambiar de opinión en cualquier momento y puede
            hacérnoslo saber siguiendo las instrucciones en la sección "Todos
            los usuarios: sus opciones y controles" a continuación.<br/><br/> (D)
            Requisitos legales<br/><br/> También podemos compartir información personal e
            información identificable del dispositivo en las siguientes
            circunstancias:<br/><br/> - si lo requiere la ley, como una orden judicial, un
            estatuto, un reglamento o una regla, a través de un proceso legal o
            con fines de aplicación de la ley;<br/> - en caso de quiebra,
            insolvencia, reorganización, administración judicial o cesión en
            beneficio de nuestros acreedores;<br/> - si lo determinamos necesario en
            relación con una investigación o para proteger nuestros derechos
            legales; o<br/> - en respuesta a solicitudes de agencias de aplicación de
            la ley y civiles federales, estatales, locales o extranjeras, como
            una orden de allanamiento, citación u orden judicial. <br/><br/>5. Servicios
            de comunicación y características de la comunidad <br/><br/>(A) Salas de chat,
            foros y tableros de mensajes<br/><br/> Los Servicios pueden poner a su
            disposición y a otros usuarios salas de chat, foros, blogs, tableros
            de mensajes y otras características y actividades de la comunidad.
            Si publica información personal en línea, estará disponible
            públicamente y es posible que reciba mensajes no solicitados de
            otras partes. No podemos garantizar la seguridad de la información
            que elija hacer pública en una sala de chat, foro o panel de
            mensajes. Además, no podemos garantizar que las partes que tengan
            acceso a dicha información disponible públicamente respeten su
            privacidad. Tenga cuidado al decidir divulgar información personal
            en estas áreas. Nos reservamos el derecho (pero no asumimos ninguna
            obligación) de revisar y/o monitorear dichas áreas comunitarias en
            los Servicios. <br/><br/>(B) Servicio de Mensajes Cortos<br/><br/> Podemos poner a su
            disposición un servicio a través del cual puede recibir mensajes en
            su dispositivo inalámbrico a través de un servicio de mensajes
            cortos ("Servicio SMS"). Usted comprende que las tarifas estándar de
            su proveedor de servicios inalámbricos se aplican a estos mensajes.<br/><br/>
            Si se suscribe a uno de nuestros Servicios de SMS, es posible que
            deba registrarse y proporcionar Información personal. También
            podemos recopilar la fecha, la hora y el contenido de sus mensajes
            en el curso de su uso del Servicio de SMS. Usaremos la información
            que obtengamos en relación con nuestro Servicio SMS con el fin de
            enviar los mensajes SMS. Si se cobran tarifas a la factura de su
            cuenta inalámbrica, podemos proporcionarle a su proveedor su
            información correspondiente para facilitar la transacción en
            relación con la misma. Su proveedor de servicios inalámbricos y
            otros proveedores de servicios también pueden recopilar datos sobre
            el uso de su dispositivo inalámbrico, y sus prácticas se rigen por
            sus propias políticas. También podemos comunicarnos con su
            proveedor, que puede acceder al contenido de su cuenta inalámbrica,
            con el fin de identificar y resolver problemas técnicos y/o quejas
            relacionadas con el Servicio. No garantizamos que su uso del
            Servicio de SMS sea privado o seguro, y no somos responsables ante
            usted por la falta de privacidad o seguridad que pueda experimentar.
            Usted es totalmente responsable de tomar precauciones y proporcionar
            las medidas de seguridad que mejor se adapten a su situación y al
            uso previsto del Servicio de SMS. Puede cambiar de opinión y darse
            de baja de los Servicios de SMS en cualquier momento siguiendo las
            instrucciones de la sección "Todos los usuarios: sus opciones y
            controles" a continuación. <br/><br/>6. Publicidad de terceros<br/><br/> Podemos
            permitir que otras empresas que brindan contenido publicitario,
            servicios de redes sociales u otros servicios en nuestro sitio web
            configuren y accedan a tecnologías de seguimiento como cookies en su
            computadora. Estas empresas pueden recopilar información sobre sus
            visitas al sitio web para medir y evaluar la eficacia de la
            publicidad en línea y para proporcionar y orientar mejor los
            anuncios sobre bienes y servicios de su interés. Si bien la
            información recopilada por estos terceros de usted mientras visita
            nuestro sitio web no es identificable personalmente, algunos de
            estos terceros también pueden combinar dicha información anonimizada
            con otra información que han recopilado de varias otras fuentes,
            como su propio pasado. información de compra en línea y/o fuera de
            línea y uso de la web de otros sitios. Dicha otra información puede
            incluir información personal, así como información demográfica y de
            comportamiento. El uso de cookies por parte de esas empresas y la
            información recopilada se rigen por las políticas de privacidad de
            esas empresas, no por las nuestras. Consulte la sección ""Todos los
            usuarios: sus opciones y controles" a continuación para conocer las
            formas de optar por no recibir esta publicidad de terceros.<br/><br/> 7. Todos
            los usuarios: sus opciones y controles <br/><br/>Tiene varias opciones con
            respecto a la recopilación y algunos de nuestros usos de la
            Información personal que recopilamos de usted en o a través del
            sitio web o los servicios. Si no ha dado su consentimiento para que
            usemos su Información personal como se describe en esta Política de
            privacidad, solo la usaremos para completar la transacción o
            proporcionar el servicio o producto que ha solicitado.<br/><br/> (A) Acceso,
            revisión, actualización, corrección o eliminación de su información
            personal<br/><br/> Si desea acceder, revisar, actualizar o corregir
            imprecisiones en su Información personal, o eliminar su Información
            personal, puede: <br/>- contáctenos a través de la web en Solicitud de
            política de privacidad de Descubri Todo; o <br/>- envíe un correo
            electrónico a privacy@DescubriTodo.com<br/> Al recibir su comunicación,
            tomaremos las medidas apropiadas para actualizar o corregir dicha
            información en nuestro poder, o para eliminarlo de nuestro catálogo
            y lista de correo. Sus preferencias incluyen, por ejemplo, la
            información de su perfil, así como cualquier información de envío,
            pago y cuenta (si corresponde), así como si desea recibir nuestras
            comunicaciones. Puede acceder a sus preferencias iniciando sesión en
            su cuenta. Si desea cerrar su cuenta con nosotros y hacer que
            eliminemos la Información personal que contiene (excepto la
            información que conservamos con fines de mantenimiento de
            registros), debe comunicarse con nosotros a través de la web en
            Solicitud de política de privacidad de Descubri Todo. Podemos
            solicitar cierta información personal con el fin de verificar la
            identidad de la persona que busca acceder a sus registros de
            información personal. <br/><br/>(B) Excluirse del marketing directo y otra
            publicidad<br/><br/> Si aceptó recibir nuestras comunicaciones o solicitudes
            (y se registró para obtener una cuenta con nosotros), y luego cambia
            de opinión, puede revisar sus preferencias comunicándose con
            nosotros en Solicitud de política de privacidad de Descubri Todo.
            También puede optar por no recibir todos nuestros correos
            electrónicos promocionales futuros haciendo clic en un enlace de
            exclusión voluntaria o "cancelar suscripción" dentro del correo
            electrónico promocional que recibe. Comprenda que si opta por no
            recibir nuestra correspondencia promocional, aún podemos
            comunicarnos con usted en relación con su otra relación,
            actividades, transacciones y comunicaciones con nosotros. Si ha
            aceptado que compartamos su información personal con terceros para
            que puedan comunicarse con usted acerca de promociones, ofertas y
            otra información, puede revisar sus preferencias comunicándose con
            nosotros en Solicitud de política de privacidad de Descubri Todo. Si
            solicita que dejemos de compartir su información personal con otras
            entidades para sus fines de marketing directo, dicha solicitud solo
            se aplicará a partir de la fecha de su solicitud, y no seremos
            responsables de ninguna comunicación que pueda recibir de entidades
            que recibido su información personal antes de dicha solicitud. En
            estos casos, comuníquese con esa entidad directamente o haga clic en
            el enlace de exclusión voluntaria o "cancelar suscripción" que debe
            incluirse en el correo electrónico promocional que recibe de dicho
            tercero. Puede optar por no recibir anuncios de los anunciantes de
            la red haciendo clic en el ícono AdChoices en los anuncios que se le
            envían, visitando las páginas de exclusión en el sitio web de NAI y
            el sitio web de DAA. La exclusión no le impide ver anuncios;
            simplemente significa que los anunciantes de la red ya no
            recopilarán datos con el fin de proporcionarle anuncios dirigidos.
            Las herramientas de exclusión de DAA y NAI se basan en cookies.
            Señalan a los anunciantes de la red para que no recopilen datos en
            línea ni entreguen anuncios específicos dirigidos a usted, y solo
            afecten el navegador web/Internet en la computadora donde están
            instaladas las cookies. Estas herramientas de exclusión solo
            funcionarán si su navegador está configurado para aceptar cookies de
            terceros. Si elimina una cookie de exclusión voluntaria o todas sus
            cookies de los archivos de cookies de un navegador, cambia de
            navegador web o cambia de computadora, ya no se excluirá de nuestra
            recopilación de datos y orientación de anuncios, y podemos colocar
            una nueva cookie a menos que una opción -out cookie se reinicia
            nuevamente en ese navegador. Optar por no usar un navegador en una
            computadora no lo excluirá de usar cualquier otro navegador en la
            misma u otra computadora.<br/><br/> 8. Residentes de California: Derechos de
            privacidad de California<br/><br/> La Sección 1798.83 del Código Civil de
            California, también conocida como la ley "Shine The Light", permite
            a nuestros clientes que son residentes de California solicitar y
            obtener de nosotros una vez al año, sin cargo, información sobre la
            Información personal que divulgamos a terceros para uso directo.
            fines de mercadeo en el año calendario anterior. Si es residente de
            California y desea una copia de este aviso, comuníquese con nosotros
            a través de la Solicitud de política de privacidad de Descubri Todo
            o escríbanos a 116 Buck Creek Trail, Alva, WY 82711 Atención:
            Relaciones. No todo el intercambio de información está cubierto por
            los requisitos de "Shine The Light" y solo la información sobre el
            intercambio cubierto se incluirá en nuestra respuesta. Según la ley
            de California, las empresas solo deben responder a una solicitud una
            vez durante cualquier año calendario. Si es residente de California,
            también tiene ciertos derechos en virtud de la Ley de Privacidad del
            Consumidor de California ("CCPA") con respecto a su Información
            personal: <br/><br/>(A) Derecho a saber<br/><br/> Tiene derecho a saber y ver qué datos
            hemos recopilado sobre usted durante los últimos 12 meses,
            incluidos:<br/> - Las categorías de Información personal que hemos
            recopilado sobre usted;<br/> - Las categorías de fuentes de las que se
            recopila la Información personal;<br/> - El propósito empresarial o
            comercial para recopilar su Información personal;<br/> - Las categorías
            de terceros con los que hemos compartido su Información personal; y
            <br/>- Las piezas específicas de información personal que hemos
            recopilado sobre usted.<br/><br/> (B) Derecho a eliminar <br/><br/>Tiene derecho a
            solicitar que eliminemos la información personal que hemos
            recopilado de usted (y solicitar a nuestros proveedores de servicios
            que hagan lo mismo). Sin embargo, hay una serie de excepciones que
            incluyen, entre otras, cuando la información es necesaria para que
            nosotros o un tercero hagamos lo siguiente: <br/>- Complete su
            transacción;<br/> - Proporcionarle un bien o servicio; <br/>- realizar un
            contrato entre nosotros y usted; <br/>- Proteger su seguridad y enjuiciar
            a los responsables de violarla;<br/> - Arreglar nuestro sistema en el
            caso de un error;<br/> - Proteger los derechos de libertad de expresión
            suyos o de otros usuarios;<br/> - Cumplir con la Ley de Privacidad de
            Comunicaciones Electrónicas de California (Cal. Penal Code § 1546 et
            seq.);<br/> - -Participar en investigaciones científicas, históricas o
            estadísticas públicas o revisadas por pares en el interés público
            que se adhiere a todas las demás leyes de ética y privacidad
            aplicables;<br/> - Cumplir con una obligación legal; o <br/>- Hacer otros usos
            internos y lícitos de la información que sean compatibles con el
            contexto en el que la proporcionó.<br/><br/> (C) Ejercicio de sus derechos<br/><br/>
            Para ejercer su derecho a saber o su derecho a eliminar, envíenos
            una solicitud de consumidor verificable como se indica en la sección
            "Contáctenos" de esta Política de privacidad. Solo usted, o alguien
            legalmente autorizado para actuar en su nombre, puede realizar una
            solicitud de consumidor verificable relacionada con su Información
            personal. Solo puede realizar una solicitud de consumidor
            verificable para ejercer su derecho a saber dos veces dentro de un
            período de 12 meses. La solicitud verificable del consumidor debe: <br/><br/>-
            Proporcione información suficiente que nos permita verificar
            razonablemente que usted es la persona sobre la que recopilamos
            información personal o un representante autorizado.<br/> - Describa su
            solicitud con suficiente detalle que nos permita comprenderla,
            evaluarla y responderla adecuadamente. <br/><br/>No podemos responder a su
            solicitud ni proporcionarle información personal si no podemos
            verificar su identidad o autoridad para realizar la solicitud y
            confirmar que la información personal se relaciona con usted. No lo
            discriminaremos por ejercer cualquiera de sus derechos de CCPA
            descritos anteriormente. A menos que lo permita la CCPA, no le
            negaremos bienes o servicios; cobrarle precios o tarifas diferentes
            por bienes o servicios; proporcionarle un nivel o calidad diferente
            de bienes o servicios; o sugerir que puede recibir un precio o
            tarifa diferente por bienes o servicios o un nivel o calidad
            diferente de bienes o servicios.<br/><br/> 9. Prácticas de seguridad<br/><br/> Hacemos
            esfuerzos de seguridad razonables para proteger la información
            personal en nuestro poder. Sin embargo, ningún método de transmisión
            o almacenamiento de datos es 100 % seguro y no seremos responsables
            de ningún daño que resulte de una violación de la seguridad de los
            datos o del acceso o uso no autorizado de la información, ya sea
            información personal o información identificable del dispositivo. En
            la medida en que proporcionemos su información personal a terceros,
            les solicitaremos que utilicen medidas de seguridad razonables para
            proteger su información. <br/><br/>10. Enlaces a otros servicios<br/><br/> Los Servicios
            pueden contener enlaces a otros sitios web y servicios en línea.
            Cuando se vincula a dichos otros sitios y servicios (incluso
            haciendo clic en hipervínculos, logotipos, widgets, pancartas o
            anuncios), queda sujeto a sus términos de uso y políticas de
            privacidad publicados allí y/o aplicables a los mismos. Debido a que
            no podemos controlar las actividades de terceros, tenga en cuenta
            que no somos responsables de las prácticas de privacidad de los
            sitios web de terceros. Le recomendamos que tenga esto en cuenta
            cuando haga clic en un enlace y abandone el sitio web, y que lea las
            políticas de privacidad de cada sitio web que recopile información
            personal sobre usted.<br/><br/> 11. Información adicional<br/><br/> Los Servicios se
            operan y mantienen dentro de los EE. UU. No garantizamos ni
            representamos de ningún tipo que los Servicios sean adecuados para
            su uso fuera de los EE. UU. o en el país en el que reside. La
            información que recopilamos se guarda en servidores en los EE. UU. y
            puede transferirse a servidores en otros países, sujeto a los
            términos de esta Política de privacidad. Conservamos la información
            personal que recopilamos mientras sigamos teniendo un propósito
            comercial para ella. Al acceder y utilizar un Servicio, establecer
            una cuenta o realizar una compra, usted acepta el procesamiento de
            su Información personal según lo dispuesto en esta Política de
            privacidad. Puede retirar ese consentimiento poniéndose en contacto
            con nosotros en privacy@DescubriTodo.com. Tenga en cuenta que cuando
            retira su consentimiento, aún podemos usar su información en la
            medida necesaria para completar una transacción con usted o según lo
            permita la ley. Debe proporcionar cierta información personal para
            usar un servicio, realizar una compra o aprovechar ciertas funciones
            disponibles a través del sitio web. Su elección de no proporcionar
            esta información puede impedirle usar los Servicios o puede resultar
            en la necesidad de cancelar su cuenta o en la imposibilidad de usar
            ciertas funciones. No es nuestra intención discriminarlo por ejercer
            sus derechos o las opciones disponibles para usted. Pero, sin cierta
            información, es posible que el sitio web y los servicios no
            funcionen según lo diseñado. Si desea objetar o presentar una queja
            sobre cómo hemos manejado su información personal, puede comunicarse
            con nosotros a través de Solicitud de política de privacidad de
            Descubri Todo. Si no está satisfecho con nuestra respuesta o cree
            que nuestro procesamiento de su información personal no está de
            acuerdo con la ley, puede presentar una queja ante la autoridad
            gubernamental correspondiente. Descubri Todo controla la información
            personal recopilada a través del sitio web y los servicios.<br/><br/> 12.
            Cambios a esta Política de Privacidad<br/><br/> Esta Política de privacidad
            reemplaza cualquier versión anterior. Debe revisar esta Política de
            privacidad periódicamente, ya que podemos modificarla de vez en
            cuando. Si cambiamos nuestra Política de privacidad en el futuro,
            publicaremos la Política de privacidad modificada en la página de
            inicio del sitio web y en otros lugares donde se publicó
            anteriormente la política. Podrá determinar qué versión de la
            Política de privacidad se aplica en la "Fecha de entrada en
            vigencia" que se encuentra debajo del título. Cuando sea consistente
            con la ley aplicable, su uso continuado de los Servicios después de
            que hayamos cambiado la Política de privacidad significa que acepta
            los términos revisados. Bajo ciertas circunstancias (p. ej., con
            respecto a ciertos cambios materiales a esta Política de
            privacidad), también podemos optar por notificarle a través de
            medios adicionales, como publicar un aviso en la página principal
            del sitio web o por medio de un correo electrónico. <br/><br/>13. Datos de
            niños<br/><br/> Debe tener al menos 13 años para usar el sitio web. Reglas
            generales: aunque la mayoría de las partes del sitio web están
            dirigidas al público en general, no recopilamos a sabiendas
            información personal de niños menores de 18 años. Si descubrimos que
            hemos recopilado o recibido información personal de un niño menor de
            18 años sin verificación del consentimiento de los padres, lo
            haremos tratar de eliminar esa información.<br/><br/> Información recopilada y
            derechos de los padres: si su hijo desea registrarse o participar en
            ciertas actividades en las áreas de los Servicios que están
            dirigidas a niños menores de 18 años, solo lo haremos si contamos
            con su consentimiento afirmativo. Durante el proceso de registro,
            podemos recopilar cierta información de su hijo, incluida
            información personal como su nombre o dirección de correo
            electrónico, así como la dirección de correo electrónico de un padre
            o tutor para obtener su consentimiento afirmativo. Luego, le
            enviaremos un correo electrónico (que incluirá un enlace a esta
            Política de privacidad), informándole sobre la parte de los
            Servicios en la que su hijo ha expresado interés y le solicitaremos
            que responda por correo electrónico si acepta. la participación de
            su hijo. En el correo electrónico de respuesta, podemos solicitarle
            más información personal (como se define en la parte principal de
            esta Política de privacidad) sobre usted y/o su hijo, pero no le
            solicitaremos dicha información a su hijo. Luego le enviaremos un
            correo electrónico de confirmación del registro de su hijo. Una vez
            que su hijo esté registrado, podemos solicitarle que proporcione
            información general, como su jugador favorito. Tiene derecho a
            revisar la Información personal de su hijo, a que se elimine esa
            información y/o a negarse a permitir su recopilación o uso
            posterior. Los padres pueden comunicarse con el administrador de
            privacidad en la dirección o el recurso de correo electrónico que se
            indica al final de esta política y responderemos a sus solicitudes.
            <br/><br/>14. Exclusiones<br/><br/> Esta Política de privacidad no se aplica a ninguna
            Información personal recopilada por DescubriTodo.com que no sea
            Información personal recopilada a través de los Servicios. Esta
            Política de privacidad tampoco se aplica a la información sobre
            empresas u otros usuarios no individuales. DescubriTodo.com se
            reserva el derecho de usar y divulgar información agregada o
            anonimizada para cualquier propósito comercialmente razonable. <br/><br/>15.
            Contáctenos <br/><br/>No dude en contactarnos si tiene preguntas o inquietudes
            sobre esta Política de privacidad o si desea ejercer sus derechos de
            protección de datos aplicables.
            <br/><br/><br/><br/>
          </p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
