import React, { useEffect, useState } from "react";

import { Link, Outlet } from "react-router-dom";

import "../styles/card.scss";

import axios from "axios";

const RecommendedCard = () => {
  const [mainPost, setMainPost] = useState(null);

  useEffect(() => {
    axios.get(axios.defaults.baseURL + "/mainpost").then((res) => {
      // console.log(res);

      setMainPost(res.data[0]);

    });
  }, []);

  return (
    <>
      {mainPost != null && (
        <>
          <Link className="card-title" to={`/posts/${mainPost._id}`}>
            <div className="card-position">
              <div className="card">
                <img className="image" src={mainPost.mainPhoto.payload} />
                <caption className="card-title">{mainPost.title}</caption>
                <button className="leer-mas">Leer mas</button>
              </div>
            </div>
          </Link>
          <Outlet />
        </>
      )}
    </>
  );
};

export default RecommendedCard;
