import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import "../../styles/LandingSub.scss";

import Fade from "@mui/material/Fade";
import Slide from "@mui/material/Slide";

import axios from "axios";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import LoadingButton from "../UX/LoadingButton";

import toast, { Toaster } from 'react-hot-toast';

const LandingSub = () => {
  const [email, setEmail] = useState("");

  const [values, setValues] = useState({
    email: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const subscribeUser = () => {
    setIsLoading(true)
    if (values.email != "") {
      axios
        .post(axios.defaults.baseURL + "/subscribe", {
          email: values.email,
        })
        .then((res) => {
          if (res) {
            console.log(res);
            setIsLoading(false)
            toast.success('Gracias por suscribirte!')
          }
        })
        .catch((err) => {
          toast.error('Algo salio mal!')
          console.log(err)
        });
    }
    toast.error('Introduce un correo!')
    setIsLoading(false)
  };

  return (
    <>
    <Toaster position="bottom-center"/>
      <div className="sub">
        {/* <Fade in={true} timeout={2000}> */}
        <div className="logo-container">
          {/* <img
              src="/assets/viajeAvion.png"
              alt=""
              className="photo"
              loading="lazy"
            /> */}
          <div className="photo">
            <LazyLoadImage
              className="img"
              effect="blur"
              src="/assets/viajeAvion.png" // use normal <img> attributes as props
              placeholderSrc="/assets/viajeAvion.png"
            />
          </div>

          <h1 className="logotipo">
            Descubri <span style={{ color: "#FF9357" }}>los mejores</span>{" "}
            <br />
            <span
              style={{
                color: "#FF9357",
              }}
            >
              destinos
            </span>{" "}
            del mundo.
          </h1>
        </div>
        {/* </Fade> */}

        <Slide direction="up" in={true} timeout={1000}>
          <div className="form">
            <h1 className="title">
              <span style={{ color: "#FF9357" }}>Suscribete</span> a nuestra
              newsletter!
            </h1>

            <div id="subscribe" className="formSub">
              <label className="label-input"> Correo Electronico</label>
              <br />
              <input
                type="email"
                placeholder="johndoe@gmail.com"
                className="email-input"
                value={values.email}
                onChange={(e) => {
                  setValues({ ...values, email: e.target.value });
                }}
              />
              <button className="submit-btn" onClick={subscribeUser}>
                {isLoading ? <LoadingButton /> : <>Suscribirse</>}
              </button>
              <h4 className="subtitle">
              Al suscribirte estas aceptando nuestros
              <Link className="link" to="/terms"> Terminos y condiciones </Link>
               y <Link className="link" to="/privacy-policy">
                {" "}
                Politicas de Privacidad
              </Link>
              .
            </h4>
            </div>
          </div>
        </Slide>
      </div>
    </>
  );
};

export default LandingSub;
