import React from "react";

import { useParams } from "react-router-dom";

import { useEffect, useState } from "react";

import parse from "html-react-parser";

import axios from "axios";

import "../../styles/post.scss";

import Loading from "../../components/UX/Loading";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Post = () => {
  let params = useParams();

  const [post, setPost] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios
      .get(axios.defaults.baseURL + "/post?id=" + params.postId)
      .then((res) => {
        setIsLoading(!isLoading);
        setPost(res.data);
      });
  }, []);

  const renderParts = (part) => {
    // console.log(part)
    switch (part.type) {
      case "SUB":
        if (part.payload != "") {
          return (
            <>
              <h2 className="post-subtitle">{part.payload}</h2> <br />
            </>
          );
        }

        break;
      case "PAR":
        return <p className="post-parr">{parse(part.payload)}</p>;
        break;
      case "IMG":
        console.log(part.payload);
        if (part.payload != "") {
          return (
            <>
                <div className="slide-image">
                  <LazyLoadImage
                    className="post-image"
                    effect="blur"
                    src={part.payload} // use normal <img> attributes as props
                    placeholderSrc={part.payload}
                  />

                  <figcaption className="article-caption">
                    {part.caption}
                  </figcaption>
                </div>
            </>
          );
        }

        break;
    }
  };

  const renderSection = (sec) => {
    switch (sec.template) {
      case "1":
        return (
          <div className="template-1">
            {sec.items.map((part) => {
              return renderParts(part);
            })}
          </div>
        );
        break;
      case "2":
        return (
          <div className="template-2">
            {sec.items.map((part) => {
              return renderParts(part);
            })}
          </div>
        );
        break;
      case "3":
        return (
          <div className="template-3">
            {sec.items.map((part) => {
              return renderParts(part);
            })}
          </div>
        );
        break;
    }
  };

  return (
    <>
      {/* {console.log(post)} */}

      {post && (
        <>
          <div className="post-container">
            {/* headline deberia mostrar mainPhoto */}
            <div className="post-headline">
              <img
                src={post[0].mainPhoto.payload}
                className="background-mainPhoto"
                loading="lazy"
              ></img>
              <h1 className="post-title"> {post[0].title}</h1>
            </div>
            <div className="show-sections">
              {post &&
                post[0].content.map((content) => {
                  return renderSection(content);
                })}
            </div>
          </div>
          {/* <div>{console.log(post)}</div> */}
        </>
      )}
      {isLoading && <Loading></Loading>}
    </>
  );
};

export default Post;
