import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { Link } from "react-router-dom";

import "../styles/navRes.scss";

const drawerWidth = 240;
const navItems = ["Home", "Blog"];

const ResponsiveNav = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: "center", backgroundColor: "#041b32" }}
    >
      {/* poner logo de descubri todo */}
      {/* <Typography variant="h6" sx={{ my: 2 }}>
        MUI
      </Typography> */}
      <Link to="/">
        <img
          style={{ width: "60px", height: "60px" }}
          src="./assets/identity/favicon-08.png"
        />
      </Link>
      <Divider />
      <List>
        <ListItem disablePadding>
          <Link to="/" style={{textDecoration:"none", color:"#f1f2f6", margin:"0 auto"}}>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary={"Home"} />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem disablePadding>
          <Link to="/blog" style={{textDecoration:"none", color:"#f1f2f6", margin:"0 auto"}}>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary={"Blog"} />
            </ListItemButton>
          </Link>
        </ListItem>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        component="nav"
        sx={{
          // paddingLeft: "6rem",
          backgroundColor: "#041b32",
          // height: "100px",
          justifyContent: "center",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Link to="/">
              <img
                class="logo"
                style={{ width: "60px", height: "60px", marginRight: "3rem" }}
                src='https://sfo3.digitaloceanspaces.com/ww-space/Descubri-todo/logos/favicon-08.png'
              />
            </Link>
          </Box>

          <Box
            sx={{ display: { xs: "none", sm: "block", paddingLeft: "2rem" } }}
          >
            {/* {navItems.map((item) => (
              <Button key={item} sx={{ color: "#fff", fontFamily: "Epilogue" }}>
                {item}
              </Button>
            ))} */}

            <Link to="/">
              <a className="nav-link-2 nav-link-ltr menu-items">Home</a>
            </Link>
            <Link to="/blog">
              <a className="nav-link-2 nav-link-ltr">Blog</a>
            </Link>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "#041b32",
              color: "#f1f2f6",
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};

ResponsiveNav.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
export default ResponsiveNav;
