import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import axios from "axios"

import ArticleCard from "../../components/BlogComponents/ArticleCard";


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const Categories = () => {

    
    const [value, setValue] = React.useState(0);

    const [categories, setCategories] = React.useState([])

    const [posts1, setPosts1] = React.useState([])

    const [posts2, setPosts2] = React.useState([])

    const [posts3, setPosts3] = React.useState([])


    const [flagVacations, setFlagVacations] = React.useState(false) 

    const [flagTurism, setFlagTurism] = React.useState(false) 

    const [flagExperiences, setFlagExperiences] = React.useState(false) 




    const [catId, setCatId] = React.useState("")

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const getPostsbyCategory1 = () => {
      // console.log('hola')
      // console.log(id)
      let result;
      axios.get(axios.defaults.baseURL + "/postbycategory?id=6303e6b6f8f0580fb77d80c5" ).then((res) => {
        console.log(res);
  
        setPosts1(res.data)
      });
    }
    const getPostsbyCategory2 = () => {
      // console.log('hola')
      // console.log(id)
      let result;
      axios.get(axios.defaults.baseURL + "/postbycategory?id=6303e6c1f8f0580fb77d80c7" ).then((res) => {
        console.log(res);
  
        setPosts2(res.data)
      });
    }
    const getPostsbyCategory3 = () => {
      // console.log('hola')
      // console.log(id)
      let result;
      axios.get(axios.defaults.baseURL + "/postbycategory?id=6303e6c9f8f0580fb77d80c9").then((res) => {
        console.log(res);
  
        setPosts3(res.data)
      });
    }


    


    // const renderCatPosts = (data) => {
    //   setFlagTurism(!flagTurism)
    //   setFlagVacations(!flagVacations)
    //   setFlagExperiences(!flagExperiences)
    //   console.log(data)
    //   if(data[0] != null){
    //     return <ArticleCard article={data[0]}></ArticleCard>

    //   }
    // }

    const getCategories = () => {

      axios.get(axios.defaults.baseURL + "/categories").then((res) => {
        setCategories(res.data)
      });

    }

    // React.useEffect(() => {
    //   getCategories()     
    // }, [])

    // React.useEffect(() => {
    //   getPostsbyCategory1()
      
    // } , [flagVacations])

    
  
    return (
      <Box sx={{ width: '50%' , marginTop:"10rem" }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
            {
              categories.map((cat, index) => {
                return <Tab label={cat.name} {...a11yProps(index)}/>
              })
            }
          </Tabs>
        </Box>
        {/* {
          categories.map((cat, index) => {
            // renderCatPosts(cat._id)
            // console.log("posts" + posts)
            return <TabPanel value = {value} index={index}>{}</TabPanel>
          })
        } */}
        {/* <TabPanel value={value} index={0} >
            {renderCatPosts(posts1)}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {renderCatPosts(posts2)}

            
        </TabPanel>
        <TabPanel value={value} index={2}>
        {renderCatPosts(posts3)}

            
        </TabPanel> */}
      </Box>
    );
}

export default Categories