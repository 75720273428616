import React from "react";

import "../../styles/facts.scss";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import ExploreIcon from "@mui/icons-material/Explore";

const Facts = () => {
  return (
    <>
      <div id="container" className="container-facts">
        <div className="containerFacts">
          <div className="rectangle">
            <h1 className="title-facts">Permitenos ayudarte</h1>
          </div>
          <div className="icons">
            <div className="icon-container ">
              <img
                className="icon2"
                src="./assets/facts-icons/Iconos-03.png"
                alt=""
              />

              <p className="subtitle-icon" style={{ marginTop: "1rem" }}>
                sitios <br /> increibles
              </p>
            </div>
            <div className="icon-container ">
              <img
                className="icon2"
                src="./assets/facts-icons/Iconos-04.png"
                alt=""
              />

              <p className="subtitle-icon" style={{ marginTop: "1rem" }}>
                Nuevos <br /> Descubrimientos
              </p>
            </div>
            <div className="icon-container ">
              <img
                className="icon2"
                src="./assets/facts-icons/Iconos-05.png"
                alt=""
              />

              <p className="subtitle-icon" style={{ marginTop: "1rem" }}>
                Vistas <br /> Espectaculares
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Facts;
