//React && components
import React, { useState, useEffect } from "react";
import './App.css';
import Landing from './pages/Landing/Landing'
import Blog from './pages/Blog/Blog'
// import Nav from './components/Nav'
import Nav from './components/ResponsiveNav'

import PrivacyPolicy from './components/PrivacyPolicy';
import Terms from './components/Terms'
import Post from './pages/Blog/Post'

import Loading from './components/UX/Loading'


//Other libraries
import axios from "axios";
import { ParallaxProvider } from 'react-scroll-parallax';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";



function App() {

  // axios.defaults.baseURL = "http://localhost:5008"; //url entorno local
  axios.defaults.baseURL = "https://api.descubritodo.com"


  const [isLoading, setIsLoading] = useState(true);

  const renderLoading = () => {
    setTimeout(function () {
      setIsLoading(!isLoading);
    }, 1000);
  };

  useEffect(() => {
    renderLoading()
  }, [])

  return (
    <>
      <ParallaxProvider>
      <Router>
      <Nav/>
      <Routes>
        <Route path="/" exact element={<Landing/>} />
        <Route path="/mision" exact element={<Landing/>} />
        <Route path="/vision" exact element={<Landing/>} />
        <Route path="/valores" exact element={<Landing/>} />
        <Route path="/blog" exact element={<Blog/>} />
        <Route path="/privacy-policy" exact element={<PrivacyPolicy/>} />
        <Route path="/terms" exact element={<Terms/>} />
        <Route path="/contact-us" exact element={<Landing/>} />
        <Route path="/posts" exact element={<Post/>}>
          <Route path=':postId' element={<Post/>}></Route>
        </Route>


      </Routes>

      </Router>

      </ParallaxProvider>
      
      {/* {isLoading && <Loading></Loading>} */}
    </>
  );
}

export default App;
